import { APICore } from './apiCore';
import {ArchiveDoctorData, CreateDoctorData, UpdateDoctorData} from "../../redux/doctors/constants";

const api = new APICore();

// account
function get_doctors(param: any) {
    const baseUrl = '/doctor/';
    return api.get(`${baseUrl}`, param);
}

function get_doctor(id:number) {
    const baseUrl = '/doctor/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_doctor(params: CreateDoctorData) {
    const baseUrl = '/doctor/';
    return api.create(`${baseUrl}`, params);
}

function update_doctor(params: UpdateDoctorData) {
    const baseUrl = '/doctor/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function archive_doctor(params: ArchiveDoctorData) {
    const baseUrl = '/doctor/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function delete_doctor(id: number) {
    const baseUrl = '/doctor/';
    return api.delete(`${baseUrl}${id}`);
}

export { get_doctors, get_doctor, create_doctor, delete_doctor, update_doctor, archive_doctor};
