import {TaskData} from "../tasks/constants";
import {ReceivedFileData} from "../files/constants";
import {VisitData} from "../visits/constants";

export enum EmployeesActionTypes {
    API_RESPONSE_SUCCESS = '@@employees/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@employees/API_RESPONSE_ERROR',

    GET_EMPLOYEES = '@@employees/GET_EMPLOYEES',
    GET_EMPLOYEE = '@@employees/GET_EMPLOYEE',
    CREATE_EMPLOYEE = '@@employees/CREATE_EMPLOYEE',
    DELETE_EMPLOYEE = '@@employees/DELETE_EMPLOYEE',
    UPDATE_EMPLOYEE = '@@employees/UPDATE_EMPLOYEE',
    GET_PROFILE = '@@employees/GET_PROFILE',
    UPDATE_PROFILE = '@@employees/UPDATE_PROFILE',
}

export interface EmployeeData {
    id: number;
    username: string;
    password: string;
    email?: string;
    is_staff: boolean;
    phone: string;
    fio: string;
    region: number;
    photo?: string;
    tasks?: TaskData[]
    presentation_set?: ReceivedFileData[]
    visits?: VisitData[]
}

export interface CreateEmployeeData {
    username: string;
    email?: string;
    is_staff: boolean;
    phone: string;
    fio: string;
    region: number;
}

export interface UpdateEmployeeData {
    id: number;
    username: string;
    email?: string;
    is_staff?: boolean;
    phone: string;
    fio: string;
    region?: number;
    photo?: number;
    password?: string | null
}

export interface EmployeesData{
    employees: EmployeeData[]
}
