// constants
import {TasksActionTypes, TasksData, TaskData, UpdateTaskData, CreateTaskData} from './constants';

export interface TasksActionType {
    type:
        | TasksActionTypes.API_RESPONSE_SUCCESS
        | TasksActionTypes.API_RESPONSE_ERROR
        | TasksActionTypes.GET_TASKS
        | TasksActionTypes.GET_TASK
        | TasksActionTypes.CREATE_TASK
        | TasksActionTypes.DELETE_TASK
        | TasksActionTypes.UPDATE_TASK
    payload: {} | string;
}

// common success
export const TasksApiResponseSuccess = (actionType: string, data: TasksData | {}): TasksActionType => ({
    type: TasksActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const TasksApiResponseError = (actionType: string, data: TasksData | {}): TasksActionType => ({
    type: TasksActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getTasks = (param:any): TasksActionType => ({
    type: TasksActionTypes.GET_TASKS,
    payload: {param},
});

export const getTask = (id: number): TasksActionType => ({
    type: TasksActionTypes.GET_TASK,
    payload: {id},
});

export const deleteTask = (id: number): TasksActionType => ({
    type: TasksActionTypes.DELETE_TASK,
    payload: {id},
});

export const updateTask = (params: UpdateTaskData): TasksActionType => {
    // console.log('asd', params)
    return {
        type: TasksActionTypes.UPDATE_TASK,
        payload: {params}
    }
};

export const createTask = (task: CreateTaskData): TasksActionType => ({
    type: TasksActionTypes.CREATE_TASK,
    payload: {task},
});
