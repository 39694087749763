// constants
import {TaskData, TasksActionTypes, TasksData} from './constants';

const INIT_STATE = {
    tasks: [],
    loading: false,
    task: {}
};

interface TasksActionType {
    type:
        | TasksActionTypes.API_RESPONSE_SUCCESS
        | TasksActionTypes.API_RESPONSE_ERROR
        | TasksActionTypes.GET_TASKS
        | TasksActionTypes.GET_TASK
        | TasksActionTypes.CREATE_TASK
        | TasksActionTypes.DELETE_TASK
        | TasksActionTypes.UPDATE_TASK
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    tasks: TaskData[] | [];
    loading?: boolean;
    value?: boolean;
    task?: {}
}

const Tasks = (state: State = INIT_STATE, action: TasksActionType): any => {
    switch (action.type) {
        case TasksActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TasksActionTypes.GET_TASKS: {
                    return {
                        ...state,
                        tasks: action.payload.data,
                        loading: false,
                    };
                }

                case TasksActionTypes.GET_TASK: {
                    return {
                        ...state,
                        task: action.payload.data,
                        loading: false,
                    };
                }

                case TasksActionTypes.CREATE_TASK: {
                    return {
                        ...state,
                        tasks: [...state.tasks, action.payload.data],
                        loading: false,
                    };
                }

                case TasksActionTypes.UPDATE_TASK: {
                    // console.log('reducer', action.payload.data)
                    return {
                        ...state,
                        // tasks: [...state.tasks, action.payload.data],
                        loading: false,
                    };
                }

                case TasksActionTypes.DELETE_TASK: {
                    // console.log('reducer', action.payload.data)
                    return {
                        ...state,
                        tasks: state.tasks.filter(task => task.id !== +action.payload.data.id),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case TasksActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TasksActionTypes.GET_TASKS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case TasksActionTypes.GET_TASK: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case TasksActionTypes.CREATE_TASK: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                case TasksActionTypes.UPDATE_TASK: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case TasksActionTypes.DELETE_TASK: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case TasksActionTypes.GET_TASKS:
            return { ...state, loading: true };
        case TasksActionTypes.GET_TASK:
            return { ...state, loading: true };
        case TasksActionTypes.CREATE_TASK:
            return { ...state, loading: true };
        case TasksActionTypes.UPDATE_TASK:
            return { ...state, loading: true };
        case TasksActionTypes.DELETE_TASK:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Tasks;
