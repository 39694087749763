const config = {
    // API_URL: process.env.REACT_APP_API_URL,
    // API_URL: 'https://marbika.herokuapp.com',
    // API_URL: 'https://marbikaconsult.herokuapp.com',
    // API_URL: 'http://62.113.98.17',
    // API_URL: 'https://elixirstudio.ru/',
    API_URL: 'https://api.marbica.com/',
};

export default config;
