import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_files as getFilesApi,
    get_file as getFileApi,
    create_string_file as createStringFileApi,
    delete_file as deleteFileApi,
    archive_file as archiveFileApi,
} from '../../helpers/';

// actions
import { filesApiResponseSuccess, filesApiResponseError } from './actions';

// constants
import {ArchiveFileData, CreateFileData, FileData, FilesActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetFile{
    payload: {
        id: number
    },
    type: string
}

interface DeleteFile{
    payload: {
        id: number
    },
    type: string
}

interface GetFiles{
    payload: {
        param: any
    },
    type: string
}

interface CreateStringFile{
    payload: {
        file: CreateFileData
    },
    type: string
}

interface ArchiveFile{
    payload: {
        param: ArchiveFileData
    },
    type: string
}

function* get_files({ payload: { param }, type }: GetFiles): SagaIterator {
    try {
        const response = yield call(getFilesApi, param);
        const Files = response.data;

        yield put(filesApiResponseSuccess(FilesActionTypes.GET_FILES, Files));
    } catch (error: any) {
        yield put(filesApiResponseError(FilesActionTypes.GET_FILES, error));
    }
}

function* get_file({ payload: { id }, type }: GetFile): SagaIterator {
    try {
        const response = yield call(getFileApi, id);
        const File = response.data;

        yield put(filesApiResponseSuccess(FilesActionTypes.GET_FILE, File));
    } catch (error: any) {
        yield put(filesApiResponseError(FilesActionTypes.GET_FILE, error));
    }
}

function* create_string_file(action: CreateStringFile): SagaIterator {
    try {
        const response = yield call(createStringFileApi, action.payload.file);
        const data = response.data;

        yield put(filesApiResponseSuccess(FilesActionTypes.CREATE_FILE, data));
    } catch (error: any) {
        yield put(filesApiResponseError(FilesActionTypes.CREATE_FILE, error));
    }
}

function* delete_file(action: DeleteFile): SagaIterator {
    try {
        // console.log('saga', action.payload.id)
        const response = yield call(deleteFileApi, action.payload.id);
        const data = response.data;

        yield put(filesApiResponseSuccess(FilesActionTypes.DELETE_FILE, data));
    } catch (error: any) {
        yield put(filesApiResponseError(FilesActionTypes.DELETE_FILE, error));
    }
}
function* archive_file(action: ArchiveFile): SagaIterator {
    try {
        const response = yield call(archiveFileApi, action.payload.param);
        const data = response.data;

        yield put(filesApiResponseSuccess(FilesActionTypes.UPDATE_FILE, data));
    } catch (error: any) {
        yield put(filesApiResponseError(FilesActionTypes.UPDATE_FILE, error));
    }
}



export function* watchGetFiles() {
    yield takeEvery(FilesActionTypes.GET_FILES, get_files);
}

export function* watchGetFile() {
    yield takeEvery(FilesActionTypes.GET_FILE, get_file);
}

export function* watchCreateStringFile() {
    yield takeEvery(FilesActionTypes.CREATE_FILE, create_string_file);
}

export function* watchDeleteFile() {
    yield takeEvery(FilesActionTypes.DELETE_FILE, delete_file);
}

export function* watchArchiveFile() {
    yield takeEvery(FilesActionTypes.UPDATE_FILE, archive_file);
}

function* FilesSaga() {
    yield all([fork(watchCreateStringFile), fork(watchGetFiles), fork(watchArchiveFile), fork(watchGetFile), fork(watchDeleteFile)]);
}

export default FilesSaga;
