import { APICore } from './apiCore';
import {CreateMyPresentsData} from "../../redux/my_presents/constants";

const api = new APICore();

// account
function get_my_presents(param: any) {
    const baseUrl = '/my_presentation/';
    return api.get(`${baseUrl}`, param);
}

function create_my_present(params: CreateMyPresentsData) {
    const baseUrl = '/my_presentation/';
    return api.create(`${baseUrl}`, params);
}

function delete_my_present(id: number) {
    const baseUrl = '/my_presentation/';
    return api.delete(`${baseUrl}${id}/`);
}


export { get_my_presents, create_my_present, delete_my_present};
