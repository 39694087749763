import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_workers as getWorkersApi,
    get_worker as getWorkerApi,
    create_worker as createWorkerApi,
    delete_worker as deleteWorkerApi,
    update_worker as updateWorkerApi,
} from '../../helpers/';

// actions
import { workersApiResponseSuccess, workersApiResponseError } from './actions';

// constants
import {CreateWorkerData, UpdateWorkerData, WorkerData, WorkersActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetWorker{
    payload: {
        id: number
    },
    type: string
}

interface GetWorkers{
    payload: {
        param: any
    },
    type: string
}

interface CreateWorker{
    payload: {
        param: CreateWorkerData
    },
    type: string
}

interface UpdateWorker{
    payload: {
        param: UpdateWorkerData
    },
    type: string
}

interface DeleteWorker{
    payload: {
        id: number
    },
    type: string
}

function* get_workers({ payload: { param }, type }: GetWorkers): SagaIterator {
    try {
        const response = yield call(getWorkersApi, param);
        const workers = response.data;

        yield put(workersApiResponseSuccess(WorkersActionTypes.GET_WORKERS, workers));
    } catch (error: any) {
        yield put(workersApiResponseError(WorkersActionTypes.GET_WORKERS, error));
    }
}

function* get_worker({ payload: { id }, type }: GetWorker): SagaIterator {
    try {
        const response = yield call(getWorkerApi, id);
        const data = response.data;

        yield put(workersApiResponseSuccess(WorkersActionTypes.GET_WORKER, data));
    } catch (error: any) {
        yield put(workersApiResponseError(WorkersActionTypes.GET_WORKER, error));
    }
}

function* create_worker({ payload: { param }, type }: CreateWorker): SagaIterator {
    try {
        const response = yield call(createWorkerApi, param);
        const data = response.data;

        yield put(workersApiResponseSuccess(WorkersActionTypes.CREATE_WORKER, data));
    } catch (error: any) {
        yield put(workersApiResponseError(WorkersActionTypes.CREATE_WORKER, error));
    }
}

function* delete_worker(action: DeleteWorker): SagaIterator {
    try {
        const response = yield call(deleteWorkerApi, action.payload.id);
        const data = response.data;

        yield put(workersApiResponseSuccess(WorkersActionTypes.DELETE_WORKER, data));
    } catch (error: any) {
        yield put(workersApiResponseError(WorkersActionTypes.DELETE_WORKER, error));
    }
}

function* update_worker(action: UpdateWorker): SagaIterator {
    try {
        const response = yield call(updateWorkerApi, action.payload.param);
        const data = response.data;

        yield put(workersApiResponseSuccess(WorkersActionTypes.UPDATE_WORKER, data));
    } catch (error: any) {
        yield put(workersApiResponseError(WorkersActionTypes.UPDATE_WORKER, error));
    }
}



export function* watchGetWorkers() {
    yield takeEvery(WorkersActionTypes.GET_WORKERS, get_workers);
}

export function* watchGetWorker() {
    yield takeEvery(WorkersActionTypes.GET_WORKER, get_worker);
}

export function* watchCreateWorker() {
    yield takeEvery(WorkersActionTypes.CREATE_WORKER, create_worker);
}

export function* watchDeleteWorker() {
    yield takeEvery(WorkersActionTypes.DELETE_WORKER, delete_worker);
}

export function* watchUpdateWorker() {
    yield takeEvery(WorkersActionTypes.UPDATE_WORKER, update_worker);
}

function* WorkersSaga() {
    yield all([fork(watchUpdateWorker), fork(watchGetWorkers), fork(watchGetWorker), fork(watchCreateWorker), fork(watchDeleteWorker)]);
}

export default WorkersSaga;
