// constants
import {VisitData, VisitsActionTypes} from './constants';

const INIT_STATE = {
    visits: [],
    loading: false,
    profile: {}
};

interface VisitsActionType {
    type:
        | VisitsActionTypes.API_RESPONSE_SUCCESS
        | VisitsActionTypes.API_RESPONSE_ERROR
        | VisitsActionTypes.GET_VISITS
        | VisitsActionTypes.GET_VISIT
        | VisitsActionTypes.CREATE_VISIT
        | VisitsActionTypes.DELETE_VISIT
        | VisitsActionTypes.UPDATE_VISIT
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    visits: VisitData[] | [];
    loading?: boolean;
    value?: boolean;
    visit?: {};
}

const Visits = (state: State = INIT_STATE, action: VisitsActionType): any => {
    switch (action.type) {
        case VisitsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case VisitsActionTypes.GET_VISITS: {
                    return {
                        ...state,
                        visits: action.payload.data,
                        loading: false,
                    };
                }

                case VisitsActionTypes.GET_VISIT: {
                    return {
                        ...state,
                        visit: action.payload.data,
                        loading: false,
                    };
                }

                case VisitsActionTypes.CREATE_VISIT: {
                    return {
                        ...state,
                        visits: [...state.visits, action.payload.data],
                        loading: false,
                    };
                }

                case VisitsActionTypes.UPDATE_VISIT: {
                    // console.log(action.payload.data)
                    return {
                        ...state,
                        visits: [...state.visits.filter(visit => visit.id !== action.payload.data.id), action.payload.data],
                        loading: false,
                    };
                }

                case VisitsActionTypes.DELETE_VISIT: {
                    return {
                        ...state,
                        visits: state.visits.filter(visit => visit.id !== action.payload.data.id),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case VisitsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case VisitsActionTypes.GET_VISITS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case VisitsActionTypes.GET_VISIT: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case VisitsActionTypes.CREATE_VISIT: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                case VisitsActionTypes.UPDATE_VISIT: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case VisitsActionTypes.DELETE_VISIT: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case VisitsActionTypes.GET_VISITS:
            return { ...state, loading: true };
        case VisitsActionTypes.GET_VISIT:
            return { ...state, loading: true };
        case VisitsActionTypes.DELETE_VISIT:
            return { ...state, loading: true };
        case VisitsActionTypes.CREATE_VISIT:
            return { ...state, loading: true };
        case VisitsActionTypes.UPDATE_VISIT:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Visits;
