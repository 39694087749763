// constants
import {ArchiveActionTypes, ArchivesData} from './constants';

export interface ArchiveActionType {
    type:
        | ArchiveActionTypes.API_RESPONSE_SUCCESS
        | ArchiveActionTypes.API_RESPONSE_ERROR
        | ArchiveActionTypes.GET_ARCHIVE
    payload: {} | string;
}

// common success
export const ArchiveApiResponseSuccess = (actionType: string, data: ArchivesData | {}): ArchiveActionType => ({
    type: ArchiveActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const ArchiveApiResponseError = (actionType: string, data: ArchivesData | {}): ArchiveActionType => ({
    type: ArchiveActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getArchive = (param:any): ArchiveActionType => ({
    type: ArchiveActionTypes.GET_ARCHIVE,
    payload: {param},
});