import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// extra pages
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));

//Doctors
const Doctors = React.lazy(() => import('../pages/doctors/Doctors'));

// Medcenters
const Medcenters = React.lazy(() => import('../pages/medcenters/Medcenters'));

// Apothekes
const Apothekes = React.lazy(() => import('../pages/apothekes/Apothekes'));

// Visits
const Scheduler = React.lazy(() => import('../pages/scheduler'));

// Tasks
const Tasks = React.lazy(() => import('../pages/tasks'));

// Storage
const Storage = React.lazy(() => import('../pages/storage/Storage'));

// My storage
const MyStorage = React.lazy(() => import('../pages/my-storage/MyStorage'));

// Analytics
const Analytics = React.lazy(() => import('../pages/analytics'));

// Employees
const Employees = React.lazy(() => import('../pages/employees/Employees'));

// Profile
const MainProfile = React.lazy(() => import('../pages/profile'));

//Professions
const Professions = React.lazy(() => import('../pages/profession/Profession'));

//Archive
const Archive = React.lazy(() => import('../pages/archive'));


// Single Doctor
const SingleDoctor = React.lazy(() => import('../pages/singleDetailPages/single-doctor'));

// Single Pharmacy Detail Page
const SinglePharmacy = React.lazy(() => import('../pages/singleDetailPages/single-pharmacy'))

// Single Clinic
const SingleClinic = React.lazy(() => import('../pages/singleDetailPages/single-clinic'));

// Single Clinic
const SingleVisit = React.lazy(() => import('../pages/singleDetailPages/single-visit'));

// Single Clinic
const SingleEmployee = React.lazy(() => import('../pages/singleDetailPages/single-employee'));
const SinglePresentation = React.lazy(() => import('../pages/singleDetailPages/single-presentation'));
const SingleVisitDone = React.lazy(() => import('../pages/singleDetailPages/single-visit-done'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

// ui
const uiRoutes: RoutesProps = {
    path: '/components',
    name: 'Components',
    icon: 'package',
    header: 'UI Elements',
    children: [
        {
            path: '/doctors',
            name: 'Doctors',
            component: Doctors,
            route: PrivateRoute,
        },
        {
            path: '/clinics',
            name: 'Clinics',
            component: Medcenters,
            route: PrivateRoute,
        },
        {
            path: '/pharmacy',
            name: 'Pharmacy',
            component: Apothekes,
            route: PrivateRoute,
        },
        {
            path: '/scheduler',
            name: 'Scheduler',
            component: Scheduler,
            route: PrivateRoute,
        },
        {
            path: '/tasks',
            name: 'Tasks',
            component: Tasks,
            route: PrivateRoute,
        },
        {
            path: '/storage',
            name: 'Storage',
            component: Storage,
            route: PrivateRoute,
        },
        {
            path: '/my-storage',
            name: 'My storage',
            component: MyStorage,
            route: PrivateRoute,
        },
        {
            path: '/analytics',
            name: 'Analytics',
            component: Analytics,
            route: PrivateRoute,
        },
        {
            path: '/employees',
            name: 'Employees',
            component: Employees,
            route: PrivateRoute,
        },
        {
            path: '/profile',
            name: 'MainProfile',
            component: MainProfile,
            route: PrivateRoute,
        },
        {
            path: '/professions',
            name: 'Professions',
            component: Professions,
            route: PrivateRoute,
        },
        {
            path: '/archive',
            name: 'Archive',
            component: Archive,
            route: PrivateRoute,
        },
        {
            path: '/doctor/:id',
            name: 'SingleDoctor',
            component: SingleDoctor,
            route: PrivateRoute,
        },
        {
            path: '/pharma/:id',
            name: 'SinglePharmacy',
            component: SinglePharmacy,
            route: PrivateRoute
        },
        {
            path: '/clinic/:id',
            name: 'SingleClinic',
            component: SingleClinic,
            route: PrivateRoute,
        },
        {
            path: '/visit/:id',
            name: 'SingleVisit',
            component: SingleVisit,
            route: PrivateRoute,
        },
        {
            path: '/visit/:id',
            name: 'SingleVisit',
            component: SingleVisit,
            route: PrivateRoute,
        },
        {
            path: '/employee/:id',
            name: 'SingleEmployee',
            component: SingleEmployee,
            route: PrivateRoute,
        },
        {
            path: '/visit-done/:id',
            name: 'single-visit-done',
            component: SingleVisitDone,
            route: PrivateRoute,
        },
        {
            path: '/presentation/:id',
            name: 'presentation',
            component: SinglePresentation,
            route: PrivateRoute,
        }
    ],
};

// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
];
// public routes
const otherPublicRoutes: RoutesProps[] = [
    {
        path: '/error',
        name: 'Error-404',
        component: Error404,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error-500',
        component: Error500,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, uiRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
