export enum WorkersActionTypes {
    API_RESPONSE_SUCCESS = '@@Workers/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@Workers/API_RESPONSE_ERROR',

    GET_WORKERS = '@@Workers/GET_WORKERS',
    GET_WORKER = '@@Workers/GET_WORKER',
    CREATE_WORKER = '@@Workers/CREATE_WORKER',
    DELETE_WORKER = '@@Workers/DELETE_WORKER',
    UPDATE_WORKER = '@@Workers/UPDATE_WORKER',
}

export interface WorkerData {
    "id": number,
    "fio": string,
    phone: string,
    pharmacy?: number
}

export interface UpdateWorkerData {
    "id": number,
    "fio": string,
    phone: string,
}

export interface CreateWorkerData {
    "pharmacy": number,
    "fio": string,
    phone: string
}

export interface WorkersData{
    workers: WorkerData[]
}
