import { APICore } from './apiCore';
import {CreateEmployeeData, EmployeeData, UpdateEmployeeData} from "../../redux/employees/constants";

const api = new APICore();

// account
function get_employees(param: any) {
    const baseUrl = '/user/';
    return api.get(`${baseUrl}`, param);
}

function update_employee(param: UpdateEmployeeData) {
    const baseUrl = '/user/';
    return api.updatePatch(`${baseUrl}${param.id}/`, param);
}

function delete_employee(param: any) {
    const baseUrl = '/user/';
    return api.delete(`${baseUrl}${param}`);
}

function get_profile(param: any) {
    const baseUrl = '/profile/';
    return api.get(`${baseUrl}`, null);
}

function update_profile(param: any) {
    const baseUrl = '/user/';
    return api.updatePatch(`${baseUrl}${param.id}`, param);
}

function get_employee(id:number) {
    const baseUrl = '/user/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_employee(params: CreateEmployeeData) {
    const baseUrl = '/user/';
    return api.create(`${baseUrl}`, params);
}

export { get_employees, get_employee, create_employee, update_employee, update_profile, delete_employee, get_profile};
