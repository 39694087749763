// constants
import {ArchiveMedcenterData, MedcentersActionTypes} from './constants';

export interface MedcentersActionType {
    type:
        | MedcentersActionTypes.API_RESPONSE_SUCCESS
        | MedcentersActionTypes.API_RESPONSE_ERROR
        | MedcentersActionTypes.GET_MEDCENTERS
        | MedcentersActionTypes.GET_MEDCENTER
        | MedcentersActionTypes.CREATE_MEDCENTER
        | MedcentersActionTypes.DELETE_MEDCENTER
        | MedcentersActionTypes.UPDATE_MEDCENTER
        | MedcentersActionTypes.ARCHIVE_MEDCENTER
    payload: {} | string;
}

interface MedcenterData {
    id?: number;
    name: string
}

interface MedcentersData{
    medcenters: MedcenterData[]
}


// common success
export const medcentersApiResponseSuccess = (actionType: string, data: MedcentersData | {}): MedcentersActionType => ({
    type: MedcentersActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const medcentersApiResponseError = (actionType: string, data: MedcentersData | {}): MedcentersActionType => ({
    type: MedcentersActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getMedcenters = (param:any): MedcentersActionType => ({
    type: MedcentersActionTypes.GET_MEDCENTERS,
    payload: {param},
});

export const getMedcenter = (id: number): MedcentersActionType => ({
    type: MedcentersActionTypes.GET_MEDCENTER,
    payload: {id},
});

export const deleteMedcenter = (id: number): MedcentersActionType => ({
    type: MedcentersActionTypes.DELETE_MEDCENTER,
    payload: {id},
});

export const updateMedcenter = (params: MedcenterData): MedcentersActionType => ({
    type: MedcentersActionTypes.UPDATE_MEDCENTER,
    payload: {params},
});

export const archiveMedcenter = (params: ArchiveMedcenterData): MedcentersActionType => ({
    type: MedcentersActionTypes.ARCHIVE_MEDCENTER,
    payload: {params},
});

export const createMedcenter = (medcenter: MedcenterData): MedcentersActionType => {
    return {
        type: MedcentersActionTypes.CREATE_MEDCENTER,
            payload: {medcenter},
    }
};
