import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_employees as getEmployeesApi,
    get_employee as getEmployeeApi,
    create_employee as createEmployeeApi,
    update_profile as updateProfileApi,
    update_employee as updateEmployeeApi,
    get_profile as getProfileApi,
    delete_employee as deleteEmployeeApi
} from '../../helpers/';

// actions
import { EmployeesApiResponseSuccess, EmployeesApiResponseError } from './actions';

// constants
import {CreateEmployeeData, EmployeeData, EmployeesActionTypes, UpdateEmployeeData} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetEmployee{
    payload: {
        id: number
    },
    type: string
}

interface DeleteEmployee{
    payload: {
        id: number
    },
    type: string
}

interface GetProfile{
    payload: {
        param: any
    },
    type: string
}

interface GetEmployees{
    payload: {
        param: any
    },
    type: string
}

interface CreateEmployee{
    payload: {
        employee: CreateEmployeeData
    },
    type: string
}

interface UpdateEmployee{
    payload: {
        params: UpdateEmployeeData
    },
    type: string
}

function* get_employees({ payload: { param }, type }: GetEmployees): SagaIterator {
    try {
        const response = yield call(getEmployeesApi, param);
        const employees = response.data;

        yield put(EmployeesApiResponseSuccess(EmployeesActionTypes.GET_EMPLOYEES, employees));
    } catch (error: any) {
        yield put(EmployeesApiResponseError(EmployeesActionTypes.GET_EMPLOYEES, error));
    }
}

function* get_employee({ payload: { id }, type }: GetEmployee): SagaIterator {
    try {
        const response = yield call(getEmployeeApi, id);
        const doctor = response.data;

        yield put(EmployeesApiResponseSuccess(EmployeesActionTypes.GET_EMPLOYEE, doctor));
    } catch (error: any) {
        yield put(EmployeesApiResponseError(EmployeesActionTypes.GET_EMPLOYEE, error));
    }
}

function* get_profile({ payload: { param }, type }: GetProfile): SagaIterator {
    try {
        const response = yield call(getProfileApi, param);
        const profile = response.data;

        yield put(EmployeesApiResponseSuccess(EmployeesActionTypes.GET_PROFILE, profile));
    } catch (error: any) {
        yield put(EmployeesApiResponseError(EmployeesActionTypes.GET_PROFILE, error));
    }
}

function* create_employee({ payload: { employee }, type }: CreateEmployee): SagaIterator {
    try {
        const response = yield call(createEmployeeApi, employee);
        const data = response.data;

        yield put(EmployeesApiResponseSuccess(EmployeesActionTypes.CREATE_EMPLOYEE, data));
    } catch (error: any) {
        yield put(EmployeesApiResponseError(EmployeesActionTypes.CREATE_EMPLOYEE, error));
    }
}

function* update_employee({ payload: { params }, type }: UpdateEmployee): SagaIterator {
    try {
        const response = yield call(updateEmployeeApi, params);
        const data = response.data;

        yield put(EmployeesApiResponseSuccess(EmployeesActionTypes.UPDATE_EMPLOYEE, data));
    } catch (error: any) {
        yield put(EmployeesApiResponseError(EmployeesActionTypes.UPDATE_EMPLOYEE, error));
    }
}

function* update_profile({ payload: { params }, type }: UpdateEmployee): SagaIterator {
    try {
        const response = yield call(updateProfileApi, params);
        const data = response.data;

        yield put(EmployeesApiResponseSuccess(EmployeesActionTypes.UPDATE_PROFILE, data));
    } catch (error: any) {
        yield put(EmployeesApiResponseError(EmployeesActionTypes.UPDATE_PROFILE, error));
    }
}

function* delete_employee({ payload: { id }, type }: DeleteEmployee): SagaIterator {
    try {
        const response = yield call(deleteEmployeeApi, id);
        const data = response.data;

        yield put(EmployeesApiResponseSuccess(EmployeesActionTypes.DELETE_EMPLOYEE, data));
    } catch (error: any) {
        yield put(EmployeesApiResponseError(EmployeesActionTypes.DELETE_EMPLOYEE, error));
    }
}

export function* watchGetEmployees() {
    yield takeEvery(EmployeesActionTypes.GET_EMPLOYEES, get_employees);
}

export function* watchGetEmployee() {
    yield takeEvery(EmployeesActionTypes.GET_EMPLOYEE, get_employee);
}

export function* watchUpdateEmployee() {
    yield takeEvery(EmployeesActionTypes.UPDATE_EMPLOYEE, update_employee);
}

export function* watchDeleteEmployee() {
    yield takeEvery(EmployeesActionTypes.DELETE_EMPLOYEE, delete_employee);
}

export function* watchCreateEmployee() {
    yield takeEvery(EmployeesActionTypes.CREATE_EMPLOYEE, create_employee);
}

export function* watchGetProfile() {
    yield takeEvery(EmployeesActionTypes.GET_PROFILE, get_profile);
}

export function* watchUpdateProfile() {
    yield takeEvery(EmployeesActionTypes.UPDATE_PROFILE, update_profile);
}

function* employeesSaga() {
    yield all([fork(watchGetEmployees), fork(watchGetEmployee), fork(watchCreateEmployee),
        fork(watchUpdateProfile), fork(watchGetProfile), fork(watchDeleteEmployee), fork(watchUpdateEmployee),
    ]);
}

export default employeesSaga;
