export enum TasksActionTypes {
    API_RESPONSE_SUCCESS = '@@tasks/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@tasks/API_RESPONSE_ERROR',

    GET_TASKS = '@@tasks/GET_TASKS',
    GET_TASK = '@@tasks/GET_TASK',
    CREATE_TASK = '@@tasks/CREATE_TASK',
    DELETE_TASK = '@@tasks/DELETE_TASK',
    UPDATE_TASK = 'UPDATE_TASK',
}

export interface TaskData {
    id: number;
    title?: string;
    status?: string;
    content?: string;
    user?: number;
    assignee?: number | null;
    assignee_name?: string;
    // totalComments?: number;
    // totalSubTasks?: number;
    // subTaskCompleted?: number;
    due_date?: string;
}


export interface CreateTaskData {
    title?: string;
    status?: string;
    content?: string;
    user?: number;
    assignee?: number | null;
    assignee_name?: string;
    // totalComments?: number;
    // totalSubTasks?: number;
    // subTaskCompleted?: number;
    due_date?: string;
}

export interface UpdateTaskData {
    id: number;
    title?: string;
    status?: string;
    content?: string;
    user?: number;
    assignee?: number;
    due_date?: string;
}

export interface TasksData{
    tasks: TaskData[]
}
