import { APICore } from './apiCore';
const api = new APICore();

// account
function get_archive(param: any) {
    const baseUrl = '/archive/';
    return api.get(`${baseUrl}`, param);
}

export { get_archive};
