import {VisitData} from "../visits/constants";
import {ReceivedFileData} from "../files/constants";
import {DoctorData} from "../doctors/constants";
import {PharmacyData} from "../pharmacies/constants";
import {MedcenterData} from "../medcenters/constants";

export enum ArchiveActionTypes {
    API_RESPONSE_SUCCESS = '@@Acrhive/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@Acrhive/API_RESPONSE_ERROR',

    GET_ARCHIVE = '@@Acrhive/GET_ARCHIVE'
}

export interface ArchiveData {
    visit: VisitData[],
    presentation: ReceivedFileData[],
    doctor: DoctorData[],
    pharmacy: PharmacyData[],
    hospital: MedcenterData[],
}

export interface ArchivesData{
    archive: ArchiveData[]
}
