export enum VisitsActionTypes {
    API_RESPONSE_SUCCESS = '@@visits/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@visits/API_RESPONSE_ERROR',

    GET_VISITS = '@@visits/GET_VISITS',
    GET_VISIT = '@@visits/GET_VISIT',
    CREATE_VISIT = '@@visits/CREATE_VISIT',
    DELETE_VISIT = '@@visits/DELETE_VISIT',
    UPDATE_VISIT = '@@visits/UPDATE_VISIT',
}

export interface VisitData {
    "id"?: number;
    "name": string;
    "date": string;
    "time": string;
    "status": string;
    "pharmacy": number,
    "pharmacy_name": string;
    "doctor": number;
    "doctor_name": string;
    "user": number;
    "user_name": string;
    presentation_name: any[];
    // presentation ?
    // presentation: number;
    presentation: any[];
    presentation_start_time: string | null;
    presentation_end_time: string | null;
    latitude: string | null;
    longtitude: string | null;
    note: string | null;
    is_archived: boolean;
    weekday: number;
}



export interface CreateVisitData {
    "name": string;
    "date": string;
    "time": string;
    "pharmacy"?: number,
    "doctor"?: number;
    "presentation": any[];
    // presentation: number;
}

export interface DoneVisitData {
    id: number;
    status: string;
    presentation_start_time: null;
    presentation_end_time: null;
    latitude: number | null;
    longtitude: number | null;
    note?: string | null;
}

export interface ArchiveVisitData {
    id: number;
    is_archived: boolean
}

export interface VisitsData{
    employees: VisitData[]
}
