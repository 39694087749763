// constants
import {DoctorsActionTypes} from './constants';

const INIT_STATE = {
    doctors: {},
    gen_info: {},
    doctor: {},
    loading: false,
};

interface DoctorData {
    id: number;
    first_name: string | null;
    username: string | null;
    phone: string | null;
    last_name: string | null;
    whatsapp: string | null;
    status: string | null;
    schedule: string | null;
    photo: string | null;
    division: number[];
    filial: number[];
    middle_name: string | null;
}

interface DoctorsInfo{
    "all_doctors": number | null,
    "cur_page": number | null,
    "max_pages": number | null
}

interface DoctorsData{
    doctors: DoctorData[],
    general_info: DoctorsInfo
}

interface DoctorsActionType {
    type:
        | DoctorsActionTypes.API_RESPONSE_SUCCESS
        | DoctorsActionTypes.API_RESPONSE_ERROR
        | DoctorsActionTypes.GET_DOCTORS
        | DoctorsActionTypes.GET_DOCTOR
        | DoctorsActionTypes.CREATE_DOCTOR
        | DoctorsActionTypes.DELETE_DOCTOR
        | DoctorsActionTypes.UPDATE_DOCTOR
        | DoctorsActionTypes.ARCHIVE_DOCTOR
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    doctors: any;
    gen_info: DoctorsInfo | {}
    doctor: DoctorData | {};
    loading?: boolean;
    value?: boolean;
}

const Doctors = (state: State = INIT_STATE, action: DoctorsActionType): any => {
    switch (action.type) {
        case DoctorsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DoctorsActionTypes.GET_DOCTORS: {
                    return {
                        ...state,
                        doctors: action.payload.data,
                        loading: false,
                    };
                }

                case DoctorsActionTypes.GET_DOCTOR: {
                    return {
                        ...state,
                        doctor: action.payload.data,
                        loading: false,
                    };
                }

                case DoctorsActionTypes.CREATE_DOCTOR: {
                    return {
                        ...state,
                        doctors: [...state.doctors, action.payload.data],
                        loading: false,
                    };
                }

                case DoctorsActionTypes.UPDATE_DOCTOR: {
                    return {
                        ...state,
                        // doctors: state.doctors.doctors.filter((doctor:any) => doctor.id !== +action.payload.data.id),
                        loading: false,
                    };
                }

                case DoctorsActionTypes.ARCHIVE_DOCTOR: {
                    return {
                        ...state,
                        // doctors: state.doctors.doctors.filter((doctor:any) => doctor.id !== +action.payload.data.id),
                        loading: false,
                    };
                }

                case DoctorsActionTypes.DELETE_DOCTOR: {
                    return {
                        ...state,
                        doctors: state.doctors.doctors.filter((doctor:any) => doctor.id !== +action.payload.data.id),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case DoctorsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DoctorsActionTypes.GET_DOCTORS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case DoctorsActionTypes.GET_DOCTOR: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case DoctorsActionTypes.CREATE_DOCTOR: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case DoctorsActionTypes.DELETE_DOCTOR: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                case DoctorsActionTypes.UPDATE_DOCTOR: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case DoctorsActionTypes.ARCHIVE_DOCTOR: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case DoctorsActionTypes.GET_DOCTORS:
            return { ...state, loading: true };
        case DoctorsActionTypes.GET_DOCTOR:
            return { ...state, loading: true };
        case DoctorsActionTypes.CREATE_DOCTOR:
            return { ...state, loading: true };
        case DoctorsActionTypes.DELETE_DOCTOR:
            return { ...state, loading: true };
        case DoctorsActionTypes.ARCHIVE_DOCTOR:
            return { ...state, loading: true };
        case DoctorsActionTypes.UPDATE_DOCTOR:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Doctors;
