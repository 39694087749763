import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_visits as getVisitsApi,
    get_visit as getVisitApi,
    create_visit as createVisitApi,
    update_visit as updateVisitApi,
    done_visit as doneVisitApi,
    delete_visit as deleteVisitApi,
    archive_visit as archiveVisitApi,
} from '../../helpers/';

// actions
import { VisitsApiResponseSuccess, VisitsApiResponseError } from './actions';

// constants
import {ArchiveVisitData, CreateVisitData, DoneVisitData, VisitData, VisitsActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetVisit{
    payload: {
        id: number
    },
    type: string
}

interface DeleteVisit{
    payload: {
        id: number
    },
    type: string
}

interface GetVisits{
    payload: {
        param: any
    },
    type: string
}

interface CreateVisit{
    payload: {
        visit: CreateVisitData
    },
    type: string
}

interface UpdateVisit{
    payload: {
        visit: VisitData
    },
    type: string
}

interface DoneVisit{
    payload: {
        param: DoneVisitData
    },
    type: string
}

interface ArchiveVisit{
    payload: {
        param: ArchiveVisitData
    },
    type: string
}


function* get_visits({ payload: { param }, type }: GetVisits): SagaIterator {
    try {
        const response = yield call(getVisitsApi, param);
        const Visits = response.data;

        yield put(VisitsApiResponseSuccess(VisitsActionTypes.GET_VISITS, Visits));
    } catch (error: any) {
        yield put(VisitsApiResponseError(VisitsActionTypes.GET_VISITS, error));
    }
}

function* get_visit({ payload: { id }, type }: GetVisit): SagaIterator {
    try {
        const response = yield call(getVisitApi, id);
        const doctor = response.data;

        yield put(VisitsApiResponseSuccess(VisitsActionTypes.GET_VISIT, doctor));
    } catch (error: any) {
        yield put(VisitsApiResponseError(VisitsActionTypes.GET_VISIT, error));
    }
}


function* create_visit(action: CreateVisit): SagaIterator {
    try {
        const response = yield call(createVisitApi, action.payload.visit);
        const data = response.data;

        yield put(VisitsApiResponseSuccess(VisitsActionTypes.CREATE_VISIT, data));
    } catch (error: any) {
        yield put(VisitsApiResponseError(VisitsActionTypes.CREATE_VISIT, error));
    }
}

function* update_visit({ payload: { visit }, type }: UpdateVisit): SagaIterator {
    try {
        const response = yield call(updateVisitApi, visit);
        const data = response.data;

        yield put(VisitsApiResponseSuccess(VisitsActionTypes.UPDATE_VISIT, data));
    } catch (error: any) {
        yield put(VisitsApiResponseError(VisitsActionTypes.UPDATE_VISIT, error));
    }
}

function* done_visit({ payload: { param }, type }: DoneVisit): SagaIterator {
    try {
        const response = yield call(doneVisitApi, param);
        const data = response.data;

        yield put(VisitsApiResponseSuccess(VisitsActionTypes.UPDATE_VISIT, data));
    } catch (error: any) {
        yield put(VisitsApiResponseError(VisitsActionTypes.UPDATE_VISIT, error));
    }
}

function* archive_visit({ payload: { param }, type }: ArchiveVisit): SagaIterator {
    try {
        const response = yield call(archiveVisitApi, param);
        const data = response.data;

        yield put(VisitsApiResponseSuccess(VisitsActionTypes.UPDATE_VISIT, data));
    } catch (error: any) {
        yield put(VisitsApiResponseError(VisitsActionTypes.UPDATE_VISIT, error));
    }
}

function* delete_visit({ payload: { id }, type }: DeleteVisit): SagaIterator {
    try {
        const response = yield call(deleteVisitApi, id);
        const data = response.data;

        yield put(VisitsApiResponseSuccess(VisitsActionTypes.DELETE_VISIT, data));
    } catch (error: any) {
        yield put(VisitsApiResponseError(VisitsActionTypes.DELETE_VISIT, error));
    }
}

export function* watchGetVisits() {
    yield takeEvery(VisitsActionTypes.GET_VISITS, get_visits);
}

export function* watchGetVisit() {
    yield takeEvery(VisitsActionTypes.GET_VISIT, get_visit);
}

export function* watchUpdateVisit() {
    yield takeEvery(VisitsActionTypes.UPDATE_VISIT, update_visit);
}

export function* watchDeleteVisit() {
    yield takeEvery(VisitsActionTypes.DELETE_VISIT, delete_visit);
}

export function* watchCreateVisit() {
    yield takeEvery(VisitsActionTypes.CREATE_VISIT, create_visit);
}

export function* watchDoneVisit() {
    yield takeEvery(VisitsActionTypes.UPDATE_VISIT, done_visit);
}

export function* watchArchiveVisit() {
    yield takeEvery(VisitsActionTypes.UPDATE_VISIT, archive_visit);
}

function* VisitsSaga() {
    yield all([fork(watchDoneVisit),fork(watchGetVisits), fork(watchGetVisit), fork(watchCreateVisit),
         fork(watchDeleteVisit), fork(watchUpdateVisit), fork(watchArchiveVisit)
    ]);
}

export default VisitsSaga;
