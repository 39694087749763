import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: { username: string; password: string }) {
    const baseUrl = '/token/';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    return api.logout();
}

export { login, logout };
