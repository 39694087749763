// constants
import {VisitsActionTypes, VisitData, VisitsData, CreateVisitData, DoneVisitData, ArchiveVisitData} from './constants';

export interface VisitsActionType {
    type:
        | VisitsActionTypes.API_RESPONSE_SUCCESS
        | VisitsActionTypes.API_RESPONSE_ERROR
        | VisitsActionTypes.GET_VISITS
        | VisitsActionTypes.GET_VISIT
        | VisitsActionTypes.CREATE_VISIT
        | VisitsActionTypes.DELETE_VISIT
        | VisitsActionTypes.UPDATE_VISIT
    payload: {} | string;
}

// common success
export const VisitsApiResponseSuccess = (actionType: string, data: VisitsData | {}): VisitsActionType => ({
    type: VisitsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const VisitsApiResponseError = (actionType: string, data: VisitsData | {}): VisitsActionType => ({
    type: VisitsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getVisits = (param:any): VisitsActionType => ({
    type: VisitsActionTypes.GET_VISITS,
    payload: {param},
});

export const getVisit = (id: number): VisitsActionType => ({
    type: VisitsActionTypes.GET_VISIT,
    payload: {id},
});

export const deleteVisit = (id: number): VisitsActionType => ({
    type: VisitsActionTypes.DELETE_VISIT,
    payload: {id},
});

export const updateVisit = (visit: VisitData): VisitsActionType => ({
    type: VisitsActionTypes.UPDATE_VISIT,
    payload: {visit},
});

export const doneVisit = (param: DoneVisitData): VisitsActionType => ({
    type: VisitsActionTypes.UPDATE_VISIT,
    payload: {param},
});

export const archiveVisit = (param: ArchiveVisitData): VisitsActionType => ({
    type: VisitsActionTypes.UPDATE_VISIT,
    payload: {param},
});

export const createVisit = (visit: CreateVisitData): VisitsActionType => ({
    type: VisitsActionTypes.CREATE_VISIT,
    payload: {visit},
});
