// constants
import {ArchiveData, ArchiveActionTypes} from './constants';

const INIT_STATE = {
    archive: {},
    loading: false,
};

interface ArchiveActionType {
    type:
        | ArchiveActionTypes.API_RESPONSE_SUCCESS
        | ArchiveActionTypes.API_RESPONSE_ERROR
        | ArchiveActionTypes.GET_ARCHIVE
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    loading?: boolean;
    value?: boolean;
    archive?: {}
}

const Archive = (state: State = INIT_STATE, action: ArchiveActionType): any => {
    switch (action.type) {
        case ArchiveActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ArchiveActionTypes.GET_ARCHIVE: {
                    return {
                        ...state,
                        archive: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case ArchiveActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ArchiveActionTypes.GET_ARCHIVE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ArchiveActionTypes.GET_ARCHIVE:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Archive;
