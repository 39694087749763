export enum DoctorsActionTypes {
    API_RESPONSE_SUCCESS = '@@doctors/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@doctors/API_RESPONSE_ERROR',

    GET_DOCTORS = '@@doctors/GET_DOCTORS',
    GET_DOCTOR = '@@doctors/GET_DOCTOR',
    CREATE_DOCTOR = '@@doctors/CREATE_DOCTOR',
    DELETE_DOCTOR = '@@doctors/DELETE_DOCTOR',
    UPDATE_DOCTOR = '@@doctors/UPDATE_DOCTOR',
    ARCHIVE_DOCTOR = '@@doctors/ARCHIVE_DOCTOR',
}

export interface DoctorData {
    id: number;
    first_name: string | null;
    username: string | null;
    phone: string | null;
    last_name: string | null;
    whatsapp: string | null;
    status: string | null;
    schedule: string | null;
    photo: string | null;
    region: number;
    division: number[];
    divisions_string: string[];
    filial: number[];
    middle_name: string | null;
    visits: DoctorVisitData[];
    "description": string;
    notes: {
        id: number;
        content: string;
    }[];
    is_archived?: boolean
}

export interface CreateDoctorData {
    first_name: string;
    phone: string;
    last_name: string;
    whatsapp: string | null;
    status: string;
    schedule: string | null;
    division: number[];
    filial?: number[];
    middle_name: string | null;
    "description": string,
    region: number
}

export interface UpdateDoctorData {
    id: number;
    first_name: string;
    phone: string;
    last_name: string;
    whatsapp: string | null;
    status: string;
    schedule: string | null;
    division: number[];
    filial?: number[];
    middle_name: string | null;
    "description": string,
    region: number,
    photo: string | null
}

export interface ArchiveDoctorData {
    id: number;
    is_archived: boolean
}

export interface DoctorVisitData{
    id: number;
    presentation_name: any[];
    user_name: string;
    name: string;
    date: string;
    time: string;
    status: string;
    doctor: number;
    user: number;
    presentation: number;
    presentation_start_time: string | null;
    presentation_end_time: string | null;
    latitude: string | null;
    longtitude: string | null;
    note?: string | null;
}

export interface DoctorsInfo{
    "all_doctors": number | null,
    "cur_page": number | null,
    "max_pages": number | null
}

export interface DoctorsData{
    doctors: DoctorData[],
    general_info: DoctorsInfo
}
