import {VisitData} from "../visits/constants";

export enum PharmaciesActionTypes {
    API_RESPONSE_SUCCESS = '@@pharmacies/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@pharmacies/API_RESPONSE_ERROR',

    GET_PHARMACIES = '@@pharmacies/GET_PHARMACIES',
    GET_PHARMACY = '@@pharmacies/GET_PHARMACY',
    CREATE_PHARMACY = '@@pharmacies/CREATE_PHARMACY',
    DELETE_PHARMACY = '@@pharmacies/DELETE_PHARMACY',
    UPDATE_PHARMACY = '@@pharmacies/UPDATE_PHARMACY',
    ARCHIVE_PHARMACY = '@@pharmacies/ARCHIVE_PHARMACY',
}

export interface WorkerSetData{
    id?: number,
    fio: string,
    phone: string
}



export interface PharmacyData {
    action?: string
    id?: number;
    "name": string,
    "number": number,
    "address": string,
    worker_set: WorkerSetData[],
    "manager": string,
    "manager_phone": string,
    "status": string,
    "region": number
    visits?: VisitData[],
    notes?: {
        id: number,
        content: string
    }[];
    is_archived?: boolean
}

export interface ArchivePharmacyData{
    "id": number;
    is_archived: boolean
}

export interface UpdatePharmacyData {
    action?: string
    id?: number;
    "name": string,
    "number": number,
    "address": string,
    "manager": string,
    "manager_phone": string,
    "status": string,
    "region": number
    visits?: VisitData[],
    notes?: {
        id: number,
        content: string
    }[];
}



export interface PharmaciesData{
    pharmacies: PharmacyData[]
}
