// constants
import {WorkerData, WorkersActionTypes} from './constants';

const INIT_STATE = {
    workers: [],
    loading: false,
    worker: {}
};

interface WorkersActionType {
    type:
        | WorkersActionTypes.API_RESPONSE_SUCCESS
        | WorkersActionTypes.API_RESPONSE_ERROR
        | WorkersActionTypes.GET_WORKERS
        | WorkersActionTypes.GET_WORKER
        | WorkersActionTypes.CREATE_WORKER
        | WorkersActionTypes.DELETE_WORKER
        | WorkersActionTypes.UPDATE_WORKER
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    workers: WorkerData[] | [];
    loading?: boolean;
    worker?: {}
}

const Workers = (state: State = INIT_STATE, action: WorkersActionType): any => {
    switch (action.type) {
        case WorkersActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case WorkersActionTypes.GET_WORKERS: {
                    return {
                        ...state,
                        workers: action.payload.data,
                        loading: false,
                    };
                }

                case WorkersActionTypes.GET_WORKER: {
                    return {
                        ...state,
                        worker: action.payload.data,
                        loading: false,
                    };
                }

                case WorkersActionTypes.CREATE_WORKER: {
                    return {
                        ...state,
                        workers: [...state.workers, action.payload.data],
                        loading: false,
                    };
                }

                case WorkersActionTypes.UPDATE_WORKER: {
                    // console.log('Worker reducer update', action.payload.data)
                    return {
                        ...state,
                        workers: state.workers.filter(Worker => Worker.id === action.payload.data?.id),
                        loading: false,
                    };
                }

                case WorkersActionTypes.DELETE_WORKER: {
                    return {
                        ...state,
                        workers: state.workers.filter(item => item.id !== action.payload.data),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case WorkersActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case WorkersActionTypes.GET_WORKERS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case WorkersActionTypes.GET_WORKER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case WorkersActionTypes.CREATE_WORKER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case WorkersActionTypes.DELETE_WORKER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case WorkersActionTypes.UPDATE_WORKER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case WorkersActionTypes.GET_WORKERS:
            return { ...state, loading: true };
        case WorkersActionTypes.GET_WORKER:
            return { ...state, loading: true };
        case WorkersActionTypes.CREATE_WORKER:
            return { ...state, loading: true };
        case WorkersActionTypes.UPDATE_WORKER:
            return { ...state, loading: true };
        case WorkersActionTypes.DELETE_WORKER:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Workers;
