import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import doctorsSaga from './doctors/saga';
import medcentersSaga from './medcenters/saga';
import pharmaciesSaga from './pharmacies/saga';
import employeesSaga from './employees/saga';
import TasksSaga from './tasks/saga';
import VisitsSaga from './visits/saga';
import FilesSaga from './files/saga';
import DivisionsSaga from './divisions/saga';
import FilialsSaga from './filials/saga';
import MyPresentsSaga from './my_presents/saga';
import NotesSaga from './notes/saga';
import WorkersSaga from './workers/saga';
import ArchiveSaga from './archive/saga';
import AnalyticsSaga from './analytics/saga';

export default function* rootSaga() {
    yield all([authSaga(), layoutSaga(), doctorsSaga(), TasksSaga(),
                     medcentersSaga(), pharmaciesSaga(), employeesSaga(), NotesSaga(), ArchiveSaga(),
                     VisitsSaga(), FilesSaga(), DivisionsSaga(), FilialsSaga(), MyPresentsSaga(), WorkersSaga(), AnalyticsSaga()]);
}
