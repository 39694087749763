import { APICore } from './apiCore';
import {ArchiveVisitData, CreateVisitData, DoneVisitData, VisitData} from "../../redux/visits/constants";

const api = new APICore();

// account
function get_visits(param: any) {
    const baseUrl = '/visit';
    // console.log('params', param)
    if(param.year || param.month) return api.get(`${baseUrl}`, param);
    else return api.get(`${baseUrl}/`, param);
}

function get_visit(id:number) {
    const baseUrl = '/visit/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_visit(params: CreateVisitData) {
    const baseUrl = '/visit/';
    return api.create(`${baseUrl}`, params);
}

function update_visit(params: VisitData) {
    // console.log('Visits params', params )
    const baseUrl = '/visit/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function done_visit(param: DoneVisitData) {
    // console.log('Visits params', param )
    const baseUrl = '/visit/';
    return api.updatePatch(`${baseUrl}${param.id}/`, param);
}

function archive_visit(param: ArchiveVisitData) {
    const baseUrl = '/visit/';
    return api.updatePatch(`${baseUrl}${param.id}/`, param);
}

function delete_visit(id: number) {
    // console.log('Visits params', id)
    const baseUrl = '/visit/';
    return api.delete(`${baseUrl}${id}/`);
}


export { get_visits, get_visit, create_visit, update_visit, delete_visit, done_visit, archive_visit};
