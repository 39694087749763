// constants
import {MyPresentsActionTypes, MyPresentsData, CreateMyPresentsData} from './constants';

export interface MyPresentsActionType {
    type:
        | MyPresentsActionTypes.API_RESPONSE_SUCCESS
        | MyPresentsActionTypes.API_RESPONSE_ERROR
        | MyPresentsActionTypes.GET_MY_PRESENTS
        | MyPresentsActionTypes.CREATE_MY_PRESENT
        | MyPresentsActionTypes.DELETE_MY_PRESENT
    payload: {} | string;
}

// common success
export const myPresentsApiResponseSuccess = (actionType: string, data: MyPresentsData | {}): MyPresentsActionType => ({
    type: MyPresentsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const myPresentsApiResponseError = (actionType: string, data: MyPresentsData | {}): MyPresentsActionType => ({
    type: MyPresentsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getMyPresents = (param:any): MyPresentsActionType => ({
    type: MyPresentsActionTypes.GET_MY_PRESENTS,
    payload: {param},
});

export const deleteMyPresent = (id: number): MyPresentsActionType => ({
    type: MyPresentsActionTypes.DELETE_MY_PRESENT,
    payload: {id},
});

export const createMyPresent = (param: CreateMyPresentsData): MyPresentsActionType => ({
    type: MyPresentsActionTypes.CREATE_MY_PRESENT,
    payload: {param},
});
