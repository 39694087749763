// constants
import {ArchiveDoctorData, CreateDoctorData, DoctorsActionTypes, UpdateDoctorData} from './constants';

export interface DoctorsActionType {
    type:
        | DoctorsActionTypes.API_RESPONSE_SUCCESS
        | DoctorsActionTypes.API_RESPONSE_ERROR
        | DoctorsActionTypes.GET_DOCTORS
        | DoctorsActionTypes.GET_DOCTOR
        | DoctorsActionTypes.CREATE_DOCTOR
        | DoctorsActionTypes.DELETE_DOCTOR
        | DoctorsActionTypes.UPDATE_DOCTOR
        | DoctorsActionTypes.ARCHIVE_DOCTOR
    payload: {} | string;
}

interface DoctorData {
    id?: number;
    first_name: string | null;
    username: string | null;
    phone: string | null;
    last_name: string | null;
    whatsapp: string | null;
    status: string | null;
    schedule: string | null;
    photo: string | null;
    division: number[];
    filial: number[];
    middle_name: string | null;
}

interface DoctorsInfo{
    "all_doctors": number | null,
    "cur_page": number | null,
    "max_pages": number | null
}

interface DoctorsData{
    doctors: DoctorData[],
    general_info: DoctorsInfo
}


// common success
export const doctorsApiResponseSuccess = (actionType: string, data: DoctorsData | {}): DoctorsActionType => ({
    type: DoctorsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const doctorsApiResponseError = (actionType: string, data: DoctorsData | {}): DoctorsActionType => ({
    type: DoctorsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getDoctors = (param:any): DoctorsActionType => ({
    type: DoctorsActionTypes.GET_DOCTORS,
    payload: {param},
});

export const getDoctor = (id: number): DoctorsActionType => ({
    type: DoctorsActionTypes.GET_DOCTOR,
    payload: {id},
});

export const deleteDoctor = (id: number): DoctorsActionType => {
    return {
        type: DoctorsActionTypes.DELETE_DOCTOR,
            payload: {id},
    }
};

export const updateDoctor = (doctor: UpdateDoctorData): DoctorsActionType => {
    // console.log('update doctot action')
    return{
        type: DoctorsActionTypes.UPDATE_DOCTOR,
        payload: {doctor},
    }
};

export const archiveDoctor = (doctor: ArchiveDoctorData): DoctorsActionType => ({
    type: DoctorsActionTypes.ARCHIVE_DOCTOR,
    payload: {doctor},
});

export const createDoctor = (doctor: CreateDoctorData): DoctorsActionType => ({
    type: DoctorsActionTypes.CREATE_DOCTOR,
    payload: {doctor},
});
