import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Doctors from './doctors/reducers';
import Medcenters from './medcenters/reducers';
import Pharmacies from './pharmacies/reducers';
import Employees from './employees/reducers';
import Tasks from './tasks/reducers';
import Visits from './visits/reducers';
import Files from './files/reducers';
import Divisions from './divisions/reducers';
import Filials from './filials/reducers';
import MyPresents from './my_presents/reducers';
import Notes from './notes/reducers';
import Workers from './workers/reducers';
import Archive from './archive/reducers';
import Analytics from './analytics/reducers';


export default combineReducers({
    Auth,
    Layout,
    Doctors,
    Medcenters,
    Pharmacies,
    Tasks,
    Employees,
    Visits,
    Files,
    Divisions,
    Filials,
    MyPresents,
    Notes,
    Workers,
    Archive,
    Analytics
});
