export enum MedcentersActionTypes {
    API_RESPONSE_SUCCESS = '@@medcenters/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@medcenters/API_RESPONSE_ERROR',

    GET_MEDCENTERS = '@@medcenters/GET_MEDCENTERS',
    GET_MEDCENTER = '@@medcenters/GET_MEDCENTER',
    CREATE_MEDCENTER = '@@medcenters/CREATE_MEDCENTER',
    DELETE_MEDCENTER = '@@medcenters/DELETE_MEDCENTER',
    UPDATE_MEDCENTER = '@@medcenters/UPDATE_MEDCENTER',
    ARCHIVE_MEDCENTER = '@@medcenters/ARCHIVE_MEDCENTER',
}

export interface MedcenterData {
    "id"?: number;
    "filial_set"?: FilialSetData[],
    "name": "string",
    is_archived?: boolean
}

export interface ArchiveMedcenterData {
    "id": number;
    is_archived: boolean
}

export interface FilialSetData{
    "id"?: number;
    "doctor_set": DoctorSetData[],
    "notes": any[],
    "address": string,
    "phone": string,
    "whatsapp": string,
    "hospital": number,
    "region": number
}

export interface DoctorSetData{
    "id"?: number,
    "fio": string,
    "phone": string,
    "division": string
}

export interface MedcentersData{
    medcenters: MedcenterData[]
}
