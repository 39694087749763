// constants
import {DivisionData, DivisionsActionTypes} from './constants';

const INIT_STATE = {
    divisions: [],
    loading: false,
    division: {}
};

interface DivisionsActionType {
    type:
        | DivisionsActionTypes.API_RESPONSE_SUCCESS
        | DivisionsActionTypes.API_RESPONSE_ERROR
        | DivisionsActionTypes.GET_DIVISIONS
        | DivisionsActionTypes.GET_DIVISION
        | DivisionsActionTypes.CREATE_DIVISION
        | DivisionsActionTypes.DELETE_DIVISION
        | DivisionsActionTypes.UPDATE_DIVISION
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    divisions: DivisionData[] | [];
    loading?: boolean;
    value?: boolean;
    division?: {}
}

const Divisions = (state: State = INIT_STATE, action: DivisionsActionType): any => {
    switch (action.type) {
        case DivisionsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DivisionsActionTypes.GET_DIVISIONS: {
                    return {
                        ...state,
                        divisions: action.payload.data,
                        loading: false,
                    };
                }

                case DivisionsActionTypes.GET_DIVISION: {
                    return {
                        ...state,
                        division: action.payload.data,
                        loading: false,
                    };
                }

                case DivisionsActionTypes.CREATE_DIVISION: {
                    return {
                        ...state,
                        divisions: [...state.divisions, action.payload.data],
                        loading: false,
                    };
                }

                case DivisionsActionTypes.UPDATE_DIVISION: {
                    // console.log('division reducer update', action.payload.data)
                    return {
                        ...state,
                        divisions: state.divisions.filter(division => division.id === action.payload.data?.id),
                        loading: false,
                    };
                }

                case DivisionsActionTypes.DELETE_DIVISION: {
                    return {
                        ...state,
                        divisions: state.divisions.filter(item => item.id !== action.payload.data),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case DivisionsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DivisionsActionTypes.GET_DIVISIONS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case DivisionsActionTypes.GET_DIVISION: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case DivisionsActionTypes.CREATE_DIVISION: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case DivisionsActionTypes.DELETE_DIVISION: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case DivisionsActionTypes.UPDATE_DIVISION: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case DivisionsActionTypes.GET_DIVISIONS:
            return { ...state, loading: true };
        case DivisionsActionTypes.GET_DIVISION:
            return { ...state, loading: true };
        case DivisionsActionTypes.CREATE_DIVISION:
            return { ...state, loading: true };
        case DivisionsActionTypes.UPDATE_DIVISION:
            return { ...state, loading: true };
        case DivisionsActionTypes.DELETE_DIVISION:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Divisions;
