import { APICore } from './apiCore';
import {CreateWorkerData, UpdateWorkerData, WorkerData} from "../../redux/workers/constants";

const api = new APICore();

// account
function get_workers(param: any) {
    const baseUrl = '/worker/';
    return api.get(`${baseUrl}`, param);
}

function get_worker(id:number) {
    const baseUrl = '/worker/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_worker(params: CreateWorkerData) {
    const baseUrl = '/worker/';
    return api.create(`${baseUrl}`, params);
}

function update_worker(params: UpdateWorkerData) {
    const baseUrl = '/worker/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function delete_worker(param: any) {
    const baseUrl = '/worker/';
    return api.delete(`${baseUrl}${param}`);
}

export { get_workers, get_worker, create_worker, delete_worker, update_worker};
