import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_filials as getFilialsApi,
    get_filial as getFilialApi,
    create_filial as createFilialApi,
    delete_filial as deleteFilialApi,
    update_filial as updateFilialApi,
} from '../../helpers/';

// actions
import { filialsApiResponseSuccess, filialsApiResponseError } from './actions';

// constants
import {CreateFilialData, FilialsActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetFilial{
    payload: {
        id: number
    },
    type: string
}

interface GetFilials{
    payload: {
        param: any
    },
    type: string
}

interface CreateFilial{
    payload: {
        param: CreateFilialData
    },
    type: string
}

interface UpdateFilial{
    payload: {
        param: CreateFilialData
    },
    type: string
}

interface DeleteFilial{
    payload: {
        id: number
    },
    type: string
}

function* get_filials({ payload: { param }, type }: GetFilials): SagaIterator {
    try {
        const response = yield call(getFilialsApi, param);
        const data = response.data;

        yield put(filialsApiResponseSuccess(FilialsActionTypes.GET_FILIALS, data));
    } catch (error: any) {
        yield put(filialsApiResponseError(FilialsActionTypes.GET_FILIALS, error));
    }
}

function* get_filial({ payload: { id }, type }: GetFilial): SagaIterator {
    try {
        const response = yield call(getFilialApi, id);
        const data = response.data;

        yield put(filialsApiResponseSuccess(FilialsActionTypes.GET_FILIAL, data));
    } catch (error: any) {
        yield put(filialsApiResponseError(FilialsActionTypes.GET_FILIAL, error));
    }
}

function* create_filial(action: CreateFilial): SagaIterator {
    try {
        const response = yield call(createFilialApi, action.payload.param);
        const data = response.data;

        yield put(filialsApiResponseSuccess(FilialsActionTypes.CREATE_FILIAL, data));
    } catch (error: any) {
        yield put(filialsApiResponseError(FilialsActionTypes.CREATE_FILIAL, error));
    }
}

function* delete_filial(action: DeleteFilial): SagaIterator {
    try {
        const response = yield call(deleteFilialApi, action.payload.id);
        const data = response.data;

        yield put(filialsApiResponseSuccess(FilialsActionTypes.DELETE_FILIAL, data));
    } catch (error: any) {
        yield put(filialsApiResponseError(FilialsActionTypes.DELETE_FILIAL, error));
    }
}

function* update_filial(action: UpdateFilial): SagaIterator {
    try {
        const response = yield call(updateFilialApi, action.payload.param);
        const data = response.data;

        yield put(filialsApiResponseSuccess(FilialsActionTypes.UPDATE_FILIAL, data));
    } catch (error: any) {
        yield put(filialsApiResponseError(FilialsActionTypes.UPDATE_FILIAL, error));
    }
}



export function* watchGetFilials() {
    yield takeEvery(FilialsActionTypes.GET_FILIALS, get_filials);
}

export function* watchGetFilial() {
    yield takeEvery(FilialsActionTypes.GET_FILIAL, get_filial);
}

export function* watchCreateFilial() {
    yield takeEvery(FilialsActionTypes.CREATE_FILIAL, create_filial);
}

export function* watchDeleteFilial() {
    yield takeEvery(FilialsActionTypes.DELETE_FILIAL, delete_filial);
}

export function* watchUpdateFilial() {
    yield takeEvery(FilialsActionTypes.UPDATE_FILIAL, update_filial);
}

function* FilialsSaga() {
    yield all([fork(watchUpdateFilial), fork(watchGetFilials), fork(watchGetFilial), fork(watchCreateFilial), fork(watchDeleteFilial)]);
}

export default FilialsSaga;
