import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_doctors as getDoctorsApi,
    get_doctor as getDoctorApi,
    create_doctor as createDoctorApi,
    delete_doctor as deleteDoctorApi,
    update_doctor as updateDoctorApi,
    archive_doctor as archiveDoctorApi,
} from '../../helpers/';

// actions
import { doctorsApiResponseSuccess, doctorsApiResponseError } from './actions';

// constants
import {ArchiveDoctorData, CreateDoctorData, DoctorsActionTypes, UpdateDoctorData} from './constants';

interface GetDoctor{
    payload: {
        id: number
    },
    type: string
}

interface GetDoctors{
    payload: {
        param: any
    },
    type: string
}

interface CreateDoctor{
    payload: {
        doctor: CreateDoctorData
    },
    type: string
}

interface UpdateDoctor{
    payload: {
        doctor: UpdateDoctorData
    },
    type: string
}

interface ArchiveDoctor{
    payload: {
        doctor: ArchiveDoctorData
    },
    type: string
}


interface DeleteDoctor{
    payload: {
        id: number
    },
    type: string
}

function* get_doctors({ payload: { param }, type }: GetDoctors): SagaIterator {
    try {
        const response = yield call(getDoctorsApi, param);
        const doctors = response.data;

        yield put(doctorsApiResponseSuccess(DoctorsActionTypes.GET_DOCTORS, doctors));
    } catch (error: any) {
        yield put(doctorsApiResponseError(DoctorsActionTypes.GET_DOCTORS, error));
    }
}

function* get_doctor({ payload: { id }, type }: GetDoctor): SagaIterator {
    try {
        const response = yield call(getDoctorApi, id);
        const doctor = response.data;

        yield put(doctorsApiResponseSuccess(DoctorsActionTypes.GET_DOCTOR, doctor));
    } catch (error: any) {
        yield put(doctorsApiResponseError(DoctorsActionTypes.GET_DOCTOR, error));
    }
}

function* create_doctor(action: CreateDoctor): SagaIterator {
    try {
        const response = yield call(createDoctorApi, action.payload.doctor);
        const data = response.data;

        yield put(doctorsApiResponseSuccess(DoctorsActionTypes.CREATE_DOCTOR, data));
    } catch (error: any) {
        yield put(doctorsApiResponseError(DoctorsActionTypes.CREATE_DOCTOR, error));
    }
}

function* update_doctor(action: UpdateDoctor): SagaIterator {
    try {
        const response = yield call(updateDoctorApi, action.payload.doctor);
        const data = response.data;

        // console.log('saga update doctor')
        yield put(doctorsApiResponseSuccess(DoctorsActionTypes.UPDATE_DOCTOR, data));
    } catch (error: any) {
        yield put(doctorsApiResponseError(DoctorsActionTypes.UPDATE_DOCTOR, error));
    }
}

function* archive_doctor(action: ArchiveDoctor): SagaIterator {
    try {
        // console.log('saga archive doctor')
        const response = yield call(archiveDoctorApi, action.payload.doctor);
        const data = response.data;

        yield put(doctorsApiResponseSuccess(DoctorsActionTypes.ARCHIVE_DOCTOR, data));
    } catch (error: any) {
        yield put(doctorsApiResponseError(DoctorsActionTypes.ARCHIVE_DOCTOR, error));
    }
}

function* delete_doctor({ payload: { id }, type }: DeleteDoctor): SagaIterator {
    try {
        const response = yield call(deleteDoctorApi, id);
        const data = response.data;

        yield put(doctorsApiResponseSuccess(DoctorsActionTypes.DELETE_DOCTOR, data));
    } catch (error: any) {
        yield put(doctorsApiResponseError(DoctorsActionTypes.DELETE_DOCTOR, error));
    }
}

export function* watchGetDoctors() {
    yield takeEvery(DoctorsActionTypes.GET_DOCTORS, get_doctors);
}

export function* watchGetDoctor() {
    yield takeEvery(DoctorsActionTypes.GET_DOCTOR, get_doctor);
}

export function* watchCreateDoctors() {
    yield takeEvery(DoctorsActionTypes.CREATE_DOCTOR, create_doctor);
}

export function* watchUpdateDoctor() {
    yield takeEvery(DoctorsActionTypes.UPDATE_DOCTOR, update_doctor);
}

export function* watchArchiveDoctor() {
    yield takeEvery(DoctorsActionTypes.ARCHIVE_DOCTOR, archive_doctor);
}

export function* watchDeleteDoctor() {
    yield takeEvery(DoctorsActionTypes.DELETE_DOCTOR, delete_doctor);
}


function* doctorsSaga() {
    yield all([fork(watchGetDoctors), fork(watchArchiveDoctor), fork(watchUpdateDoctor), fork(watchGetDoctor), fork(watchCreateDoctors), fork(watchDeleteDoctor)]);
}

export default doctorsSaga;
