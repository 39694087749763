// constants
import {
    ArchivePharmacyData,
    PharmaciesActionTypes,
    PharmaciesData,
    PharmacyData,
    UpdatePharmacyData
} from './constants';

export interface PharmaciesActionType {
    type:
        | PharmaciesActionTypes.API_RESPONSE_SUCCESS
        | PharmaciesActionTypes.API_RESPONSE_ERROR
        | PharmaciesActionTypes.GET_PHARMACIES
        | PharmaciesActionTypes.GET_PHARMACY
        | PharmaciesActionTypes.CREATE_PHARMACY
        | PharmaciesActionTypes.DELETE_PHARMACY
        | PharmaciesActionTypes.UPDATE_PHARMACY
        | PharmaciesActionTypes.ARCHIVE_PHARMACY
    payload: {} | string;
}

// common success
export const pharmaciesApiResponseSuccess = (actionType: string, data: PharmaciesData | {}): PharmaciesActionType => ({
    type: PharmaciesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const pharmaciesApiResponseError = (actionType: string, data: PharmaciesData | {}): PharmaciesActionType => ({
    type: PharmaciesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getPharmacies = (param:any): PharmaciesActionType => ({
    type: PharmaciesActionTypes.GET_PHARMACIES,
    payload: {param},
});

export const getPharmacy = (id: number): PharmaciesActionType => ({
    type: PharmaciesActionTypes.GET_PHARMACY,
    payload: {id},
});

export const deletePharmacy = (id: number): PharmaciesActionType => ({
    type: PharmaciesActionTypes.DELETE_PHARMACY,
    payload: {id},
});

export const updatePharmacyData = (params: UpdatePharmacyData): PharmaciesActionType => ({
    type: PharmaciesActionTypes.UPDATE_PHARMACY,
    payload: {params},
});

export const archivePharmacyData = (params: ArchivePharmacyData): PharmaciesActionType => ({
    type: PharmaciesActionTypes.ARCHIVE_PHARMACY,
    payload: {params},
});

export const createPharmacy = (pharmacy: PharmacyData): PharmaciesActionType => ({
    type: PharmaciesActionTypes.CREATE_PHARMACY,
    payload: {pharmacy},
});
