import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_medcenters as getMedcentersApi,
    get_medcenter as getMedcenterApi,
    create_medcenter as createMedcenterApi,
    update_medcenter as updateMedcenterApi,
    archive_medcenter as archiveMedcenterApi,
    delete_medcenter as deleteMedcenterApi
} from '../../helpers/';

// actions
import { medcentersApiResponseSuccess, medcentersApiResponseError } from './actions';

// constants
import {ArchiveMedcenterData, MedcenterData, MedcentersActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetMedcenter{
    payload: {
        id: number
    },
    type: string
}

interface GetMedcenters{
    payload: {
        param: any
    },
    type: string
}

interface CreateMedcenter{
    payload: {
        medcenter: MedcenterData
    },
    type: string
}

interface UpdateMedcenter{
    payload: {
        params: MedcenterData
    },
    type: string
}

interface ArchiveMedcenter{
    payload: {
        params: ArchiveMedcenterData
    },
    type: string
}

interface DeleteMedcenter{
    payload: {
        id: number
    },
    type: string
}

function* get_medcenters({ payload: { param }, type }: GetMedcenters): SagaIterator {
    try {
        const response = yield call(getMedcentersApi, param);
        const medcenters = response.data;

        yield put(medcentersApiResponseSuccess(MedcentersActionTypes.GET_MEDCENTERS, medcenters));
    } catch (error: any) {
        yield put(medcentersApiResponseError(MedcentersActionTypes.GET_MEDCENTERS, error));
    }
}

function* get_medcenter({ payload: { id }, type }: GetMedcenter): SagaIterator {
    try {
        const response = yield call(getMedcenterApi, id);
        const doctor = response.data;

        yield put(medcentersApiResponseSuccess(MedcentersActionTypes.GET_MEDCENTER, doctor));
    } catch (error: any) {
        yield put(medcentersApiResponseError(MedcentersActionTypes.GET_MEDCENTER, error));
    }
}

function* create_medcenter(action: CreateMedcenter): SagaIterator {
    try {

        const response = yield call(createMedcenterApi, action.payload.medcenter);
        const data = response.data;

        yield put(medcentersApiResponseSuccess(MedcentersActionTypes.CREATE_MEDCENTER, data));
    } catch (error: any) {
        yield put(medcentersApiResponseError(MedcentersActionTypes.CREATE_MEDCENTER, error));
    }
}

function* update_medcenter(action: UpdateMedcenter): SagaIterator {
    try {

        const response = yield call(updateMedcenterApi, action.payload.params);
        const data = response.data;

        yield put(medcentersApiResponseSuccess(MedcentersActionTypes.UPDATE_MEDCENTER, data));
    } catch (error: any) {
        yield put(medcentersApiResponseError(MedcentersActionTypes.UPDATE_MEDCENTER, error));
    }
}

function* archive_medcenter(action: ArchiveMedcenter): SagaIterator {
    try {
        const response = yield call(archiveMedcenterApi, action.payload.params);
        const data = response.data;

        yield put(medcentersApiResponseSuccess(MedcentersActionTypes.ARCHIVE_MEDCENTER, data));
    } catch (error: any) {
        yield put(medcentersApiResponseError(MedcentersActionTypes.ARCHIVE_MEDCENTER, error));
    }
}

function* delete_medcenter(action: DeleteMedcenter): SagaIterator {
    try {

        const response = yield call(deleteMedcenterApi, action.payload.id);
        const data = response.data;

        yield put(medcentersApiResponseSuccess(MedcentersActionTypes.DELETE_MEDCENTER, data));
    } catch (error: any) {
        yield put(medcentersApiResponseError(MedcentersActionTypes.DELETE_MEDCENTER, error));
    }
}


export function* watchGetMedcenters() {
    yield takeEvery(MedcentersActionTypes.GET_MEDCENTERS, get_medcenters);
}

export function* watchGetMedcenter() {
    yield takeEvery(MedcentersActionTypes.GET_MEDCENTER, get_medcenter);
}

export function* watchCreateMedcenter() {
    yield takeEvery(MedcentersActionTypes.CREATE_MEDCENTER, create_medcenter);
}

export function* watchUpdateMedcenter() {
    yield takeEvery(MedcentersActionTypes.UPDATE_MEDCENTER, update_medcenter);
}
export function* watchArchiveMedcenter() {
    yield takeEvery(MedcentersActionTypes.ARCHIVE_MEDCENTER, archive_medcenter);
}

export function* watchDeleteMedcenter() {
    yield takeEvery(MedcentersActionTypes.DELETE_MEDCENTER, delete_medcenter);
}

function* medcentersSaga() {
    yield all([fork(watchUpdateMedcenter), fork(watchArchiveMedcenter), fork(watchGetMedcenters), fork(watchGetMedcenter), fork(watchCreateMedcenter), fork(watchDeleteMedcenter)]);
}

export default medcentersSaga;
