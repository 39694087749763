// constants
import {MedcenterData, MedcentersActionTypes, MedcentersData} from './constants';
import {FilesActionTypes} from "../files/constants";

const INIT_STATE = {
    medcenters: [],
    loading: false,
    medcenter: {}
};

interface MedcentersActionType {
    type:
        | MedcentersActionTypes.API_RESPONSE_SUCCESS
        | MedcentersActionTypes.API_RESPONSE_ERROR
        | MedcentersActionTypes.GET_MEDCENTERS
        | MedcentersActionTypes.GET_MEDCENTER
        | MedcentersActionTypes.CREATE_MEDCENTER
        | MedcentersActionTypes.DELETE_MEDCENTER
        | MedcentersActionTypes.UPDATE_MEDCENTER
        | MedcentersActionTypes.ARCHIVE_MEDCENTER
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    medcenters: MedcenterData[] | [];
    loading?: boolean;
    value?: boolean;
    medcenter?: {}
}

const Medcenters = (state: State = INIT_STATE, action: MedcentersActionType): any => {
    switch (action.type) {
        case MedcentersActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MedcentersActionTypes.GET_MEDCENTERS: {
                    return {
                        ...state,
                        medcenters: action.payload.data,
                        loading: false,
                    };
                }

                case MedcentersActionTypes.GET_MEDCENTER: {
                    return {
                        ...state,
                        medcenter: action.payload.data,
                        loading: false,
                    };
                }

                case MedcentersActionTypes.CREATE_MEDCENTER: {
                    return {
                        ...state,
                        medcenters: [...state.medcenters, action.payload.data],
                        loading: false,
                    };
                }

                case MedcentersActionTypes.UPDATE_MEDCENTER: {
                    return {
                        ...state,
                        // medcenters: state.medcenters.filter(doctor => doctor.id === action.payload.data?.id),
                        loading: false,
                    };
                }

                case MedcentersActionTypes.ARCHIVE_MEDCENTER: {
                    return {
                        ...state,
                        // medcenters: state.medcenters.filter(doctor => doctor.id === action.payload.data?.id),
                        loading: false,
                    };
                }

                case MedcentersActionTypes.DELETE_MEDCENTER: {
                    // console.log('reducer', action.payload.data)
                    return {
                        ...state,
                        medcenters: state.medcenters.filter(medcenter => medcenter.id !== +action.payload.data.id),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case MedcentersActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MedcentersActionTypes.GET_MEDCENTERS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case MedcentersActionTypes.GET_MEDCENTER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case MedcentersActionTypes.CREATE_MEDCENTER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case MedcentersActionTypes.UPDATE_MEDCENTER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case MedcentersActionTypes.ARCHIVE_MEDCENTER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case MedcentersActionTypes.DELETE_MEDCENTER: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case MedcentersActionTypes.GET_MEDCENTERS:
            return { ...state, loading: true };
        case MedcentersActionTypes.GET_MEDCENTER:
            return { ...state, loading: true };
        case MedcentersActionTypes.CREATE_MEDCENTER:
            return { ...state, loading: true };
        case MedcentersActionTypes.DELETE_MEDCENTER:
            return { ...state, loading: true };
        case MedcentersActionTypes.UPDATE_MEDCENTER:
            return { ...state, loading: true };

        case MedcentersActionTypes.ARCHIVE_MEDCENTER:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Medcenters;
