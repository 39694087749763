export enum MyPresentsActionTypes {
    API_RESPONSE_SUCCESS = '@@MyPresents/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@MyPresents/API_RESPONSE_ERROR',

    GET_MY_PRESENTS = '@@MyPresents/GET_MY_PRESENTS',
    CREATE_MY_PRESENT = '@@MyPresents/CREATE_MY_PRESENT',
    DELETE_MY_PRESENT = '@@MyPresents/DELETE_MY_PRESENT',
}

export interface MyPresentData extends File {
    id?: number;
    preview?: string;
    formattedSize?: string;
}

export interface ReceivedMyPresentsData{
    presentation: {
        id: number;
        "name": string,
        "document": string,
        "doc_type": string,
        "user": number
    }
}

export interface CreateMyPresentsData{
    "presentation": number
}

export interface MyPresentsData{
    my_presents: MyPresentData[]
}