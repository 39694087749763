// constants
import {WorkersActionTypes, WorkersData, WorkerData, CreateWorkerData, UpdateWorkerData} from './constants';

export interface WorkersActionType {
    type:
        | WorkersActionTypes.API_RESPONSE_SUCCESS
        | WorkersActionTypes.API_RESPONSE_ERROR
        | WorkersActionTypes.GET_WORKERS
        | WorkersActionTypes.GET_WORKER
        | WorkersActionTypes.CREATE_WORKER
        | WorkersActionTypes.DELETE_WORKER
        | WorkersActionTypes.UPDATE_WORKER
    payload: {} | string;
}

// common success
export const workersApiResponseSuccess = (actionType: string, data: WorkersData | {}): WorkersActionType => ({
    type: WorkersActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const workersApiResponseError = (actionType: string, data: WorkersData | {}): WorkersActionType => ({
    type: WorkersActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getWorkers = (param:any): WorkersActionType => ({
    type: WorkersActionTypes.GET_WORKERS,
    payload: {param},
});

export const getWorker = (id: number): WorkersActionType => ({
    type: WorkersActionTypes.GET_WORKER,
    payload: {id},
});

export const deleteWorker = (id: number): WorkersActionType => ({
    type: WorkersActionTypes.DELETE_WORKER,
    payload: {id},
});

export const updateWorker = (param: UpdateWorkerData): WorkersActionType => ({
    type: WorkersActionTypes.UPDATE_WORKER,
    payload: {param},
});

export const createWorker = (param: CreateWorkerData): WorkersActionType => ({
    type: WorkersActionTypes.CREATE_WORKER,
    payload: {param},
});
