export enum DivisionsActionTypes {
    API_RESPONSE_SUCCESS = '@@Divisions/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@Divisions/API_RESPONSE_ERROR',

    GET_DIVISIONS = '@@Divisions/GET_DIVISIONS',
    GET_DIVISION = '@@Divisions/GET_DIVISION',
    CREATE_DIVISION = '@@Divisions/CREATE_DIVISION',
    DELETE_DIVISION = '@@Divisions/DELETE_DIVISION',
    UPDATE_DIVISION = '@@Divisions/UPDATE_DIVISION',
}

export interface DivisionData {
    "id"?: number,
    "name": string
}

export interface DivisionsData{
    divisions: DivisionData[]
}
