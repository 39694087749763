import { APICore } from './apiCore';
import {CreateTaskData, TaskData, UpdateTaskData} from "../../redux/tasks/constants";

const api = new APICore();

// account
function get_tasks(param: any) {
    const baseUrl = '/task/';
    return api.get(`${baseUrl}`, param);
}

function get_task(id:number) {
    const baseUrl = '/task/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_task(params: CreateTaskData) {
    const baseUrl = '/task/';
    return api.create(`${baseUrl}`, params);
}

function update_task(params: UpdateTaskData) {
    // console.log('tasks params', params )
    const baseUrl = '/task/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function delete_task(id: number) {
    // console.log('tasks params', id)
    const baseUrl = '/task/';
    return api.delete(`${baseUrl}${id}/`);
}


export { get_tasks, get_task, create_task, update_task, delete_task};
