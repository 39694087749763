import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_analytics as getAnalyticsApi
} from '../../helpers/';

// actions
import { AnalyticsApiResponseSuccess, AnalyticsApiResponseError } from './actions';

// constants
import {AnalyticsData, AnalyticsActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetAnalytics{
    payload: {
        param: any
    },
    type: string
}

function* get_analytics({ payload: { param }, type }: GetAnalytics): SagaIterator {
    try {
        const response = yield call(getAnalyticsApi, param);
        const Analytics = response.data;

        yield put(AnalyticsApiResponseSuccess(AnalyticsActionTypes.GET_ANALYTICS, Analytics));
    } catch (error: any) {
        yield put(AnalyticsApiResponseError(AnalyticsActionTypes.GET_ANALYTICS, error));
    }
}

export function* watchGetAnalytics() {
    yield takeEvery(AnalyticsActionTypes.GET_ANALYTICS, get_analytics);
}

function* AnalyticsSaga() {
    yield all([fork(watchGetAnalytics)]);
}

export default AnalyticsSaga;
