import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    get_my_presents as getMyPresentsApi,
    create_my_present as createMyPresentApi,
    delete_my_present as deleteMyPresentApi,
} from '../../helpers/';

// actions
import { myPresentsApiResponseSuccess, myPresentsApiResponseError } from './actions';

// constants
import {CreateMyPresentsData, MyPresentsActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetMyPresents{
    payload: {
        param: any
    },
    type: string
}

interface CreateMyPresent{
    payload: {
        param: CreateMyPresentsData
    },
    type: string
}

interface DeleteMyPresent{
    payload: {
        id: number
    },
    type: string
}

function* get_MyPresents({ payload: { param }, type }: GetMyPresents): SagaIterator {
    try {
        const response = yield call(getMyPresentsApi, param);
        const data = response.data;

        yield put(myPresentsApiResponseSuccess(MyPresentsActionTypes.GET_MY_PRESENTS, data));
    } catch (error: any) {
        yield put(myPresentsApiResponseError(MyPresentsActionTypes.GET_MY_PRESENTS, error));
    }
}

function* create_MyPresent(action: CreateMyPresent): SagaIterator {
    try {
        const response = yield call(createMyPresentApi, action.payload.param);
        const data = response.data;

        yield put(myPresentsApiResponseSuccess(MyPresentsActionTypes.CREATE_MY_PRESENT, data));
    } catch (error: any) {
        yield put(myPresentsApiResponseError(MyPresentsActionTypes.CREATE_MY_PRESENT, error));
    }
}

function* delete_MyPresent(action: DeleteMyPresent): SagaIterator {
    try {
        const response = yield call(deleteMyPresentApi, action.payload.id);
        const data = response.data;

        yield put(myPresentsApiResponseSuccess(MyPresentsActionTypes.DELETE_MY_PRESENT, data));
    } catch (error: any) {
        yield put(myPresentsApiResponseError(MyPresentsActionTypes.DELETE_MY_PRESENT, error));
    }
}

export function* watchGetMyPresents() {
    yield takeEvery(MyPresentsActionTypes.GET_MY_PRESENTS, get_MyPresents);
}

export function* watchCreateMyPresent() {
    yield takeEvery(MyPresentsActionTypes.CREATE_MY_PRESENT, create_MyPresent);
}

export function* watchDeleteMyPresent() {
    yield takeEvery(MyPresentsActionTypes.DELETE_MY_PRESENT, delete_MyPresent);
}

function* MyPresentsSaga() {
    yield all([ fork(watchGetMyPresents), fork(watchCreateMyPresent), fork(watchDeleteMyPresent)]);
}

export default MyPresentsSaga;
