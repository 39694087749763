// constants
import {FilialData, FilialsActionTypes} from './constants';

const INIT_STATE = {
    filials: [],
    loading: false,
    filial: {}
};

interface FilialsActionType {
    type:
        | FilialsActionTypes.API_RESPONSE_SUCCESS
        | FilialsActionTypes.API_RESPONSE_ERROR
        | FilialsActionTypes.GET_FILIALS
        | FilialsActionTypes.GET_FILIAL
        | FilialsActionTypes.CREATE_FILIAL
        | FilialsActionTypes.DELETE_FILIAL
        | FilialsActionTypes.UPDATE_FILIAL
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    filials: FilialData[] | [];
    loading?: boolean;
    value?: boolean;
    filial?: {}
}

const Filials = (state: State = INIT_STATE, action: FilialsActionType): any => {
    switch (action.type) {
        case FilialsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FilialsActionTypes.GET_FILIALS: {
                    return {
                        ...state,
                        filials: action.payload.data,
                        loading: false,
                    };
                }

                case FilialsActionTypes.GET_FILIAL: {
                    return {
                        ...state,
                        filial: action.payload.data,
                        loading: false,
                    };
                }

                case FilialsActionTypes.CREATE_FILIAL: {
                    return {
                        ...state,
                        filials: [...state.filials, action.payload.data],
                        loading: false,
                    };
                }

                case FilialsActionTypes.UPDATE_FILIAL: {
                    // console.log('Filial reducer update', action.payload.data)
                    return {
                        ...state,
                        filials: state.filials.filter(filial => filial.id === action.payload.data?.id),
                        loading: false,
                    };
                }

                case FilialsActionTypes.DELETE_FILIAL: {
                    return {
                        ...state,
                        filials: state.filials.filter(item => item.id !== action.payload.data),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case FilialsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FilialsActionTypes.GET_FILIALS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case FilialsActionTypes.GET_FILIAL: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case FilialsActionTypes.CREATE_FILIAL: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case FilialsActionTypes.DELETE_FILIAL: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case FilialsActionTypes.UPDATE_FILIAL: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case FilialsActionTypes.GET_FILIALS:
            return { ...state, loading: true };
        case FilialsActionTypes.GET_FILIAL:
            return { ...state, loading: true };
        case FilialsActionTypes.CREATE_FILIAL:
            return { ...state, loading: true };
        case FilialsActionTypes.UPDATE_FILIAL:
            return { ...state, loading: true };
        case FilialsActionTypes.DELETE_FILIAL:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Filials;
