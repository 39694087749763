// constants
import {FilialsActionTypes, FilialsData, FilialData, CreateFilialData} from './constants';

export interface FilialsActionType {
    type:
        | FilialsActionTypes.API_RESPONSE_SUCCESS
        | FilialsActionTypes.API_RESPONSE_ERROR
        | FilialsActionTypes.GET_FILIALS
        | FilialsActionTypes.GET_FILIAL
        | FilialsActionTypes.CREATE_FILIAL
        | FilialsActionTypes.DELETE_FILIAL
        | FilialsActionTypes.UPDATE_FILIAL
    payload: {} | string;
}

// common success
export const filialsApiResponseSuccess = (actionType: string, data: FilialsData | {}): FilialsActionType => ({
    type: FilialsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const filialsApiResponseError = (actionType: string, data: FilialsData | {}): FilialsActionType => ({
    type: FilialsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getFilials = (param:any): FilialsActionType => ({
    type: FilialsActionTypes.GET_FILIALS,
    payload: {param},
});

export const getFilial = (id: number): FilialsActionType => ({
    type: FilialsActionTypes.GET_FILIAL,
    payload: {id},
});

export const deleteFilial = (id: number): FilialsActionType => ({
    type: FilialsActionTypes.DELETE_FILIAL,
    payload: {id},
});

export const updateFilialData = (param: CreateFilialData): FilialsActionType => ({
    type: FilialsActionTypes.UPDATE_FILIAL,
    payload: {param},
});

export const createFilial = (param: CreateFilialData): FilialsActionType => ({
    type: FilialsActionTypes.CREATE_FILIAL,
    payload: {param},
});
