import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_archive as getArchiveApi
} from '../../helpers/';

// actions
import { ArchiveApiResponseSuccess, ArchiveApiResponseError } from './actions';

// constants
import {ArchiveActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetArchive{
    payload: {
        param: any
    },
    type: string
}

function* get_archive({ payload: { param }, type }: GetArchive): SagaIterator {
    try {
        const response = yield call(getArchiveApi, param);
        const Archive = response.data;

        yield put(ArchiveApiResponseSuccess(ArchiveActionTypes.GET_ARCHIVE, Archive));
    } catch (error: any) {
        yield put(ArchiveApiResponseError(ArchiveActionTypes.GET_ARCHIVE, error));
    }
}

export function* watchGetArchive() {
    yield takeEvery(ArchiveActionTypes.GET_ARCHIVE, get_archive);
}

function* ArchiveSaga() {
    yield all([fork(watchGetArchive)]);
}

export default ArchiveSaga;
