// constants
import {MyPresentData, MyPresentsActionTypes} from './constants';

const INIT_STATE = {
    my_presents: [],
    loading: false,
    my_present: {}
};

interface MyPresentsActionType {
    type:
        | MyPresentsActionTypes.API_RESPONSE_SUCCESS
        | MyPresentsActionTypes.API_RESPONSE_ERROR
        | MyPresentsActionTypes.GET_MY_PRESENTS
        | MyPresentsActionTypes.CREATE_MY_PRESENT
        | MyPresentsActionTypes.DELETE_MY_PRESENT
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    my_presents: MyPresentData[] | [];
    loading?: boolean;
    value?: boolean;
    my_present?: {}
}

const MyPresents = (state: State = INIT_STATE, action: MyPresentsActionType): any => {
    switch (action.type) {
        case MyPresentsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MyPresentsActionTypes.GET_MY_PRESENTS: {
                    return {
                        ...state,
                        my_presents: action.payload.data,
                        loading: false,
                    };
                }

                case MyPresentsActionTypes.CREATE_MY_PRESENT: {
                    return {
                        ...state,
                        my_presents: [...state.my_presents, action.payload.data],
                        loading: false,
                    };
                }

                case MyPresentsActionTypes.DELETE_MY_PRESENT: {
                    return {
                        ...state,
                        my_presents: state.my_presents.filter(item => item.id !== action.payload.data),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case MyPresentsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MyPresentsActionTypes.GET_MY_PRESENTS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case MyPresentsActionTypes.CREATE_MY_PRESENT: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case MyPresentsActionTypes.DELETE_MY_PRESENT: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case MyPresentsActionTypes.GET_MY_PRESENTS:
            return { ...state, loading: true };
        case MyPresentsActionTypes.CREATE_MY_PRESENT:
            return { ...state, loading: true };
        case MyPresentsActionTypes.DELETE_MY_PRESENT:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default MyPresents;
