export enum FilialsActionTypes {
    API_RESPONSE_SUCCESS = '@@filials/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@filials/API_RESPONSE_ERROR',

    GET_FILIALS = '@@filials/GET_FILIALS',
    GET_FILIAL = '@@filials/GET_FILIAL',
    CREATE_FILIAL = '@@filials/CREATE_FILIAL',
    DELETE_FILIAL = '@@filials/DELETE_FILIAL',
    UPDATE_FILIAL = '@@filials/UPDATE_FILIAL',
}

export interface FilialData {
    "id": number,
    "name": string,
    "doctor_set": {
            "id": number,
            "fio": string,
            "phone": string,
            "division": string
    }[],
    "notes": {
        id: number;
        content: string
    }[],
    "address": string,
    "phone": string,
    "whatsapp": string,
    "hospital": number,
    "region": number
}

export interface CreateFilialData {
    "id"?: number,
    "address": string,
    "phone": string,
    "whatsapp": string,
    "hospital": number,
    "region": number,
}


export interface FilialsData{
    filials: FilialData[]
}
