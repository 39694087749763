import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_tasks as getTasksApi,
    get_task as getTaskApi,
    create_task as createTaskApi,
    update_task as updateTaskApi,
    delete_task as deleteTaskApi,
} from '../../helpers/';

// actions
import { TasksApiResponseSuccess, TasksApiResponseError } from './actions';

// constants
import {CreateTaskData, TaskData, TasksActionTypes, UpdateTaskData} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetTask{
    payload: {
        id: number
    },
    type: string
}

interface DeleteTask{
    payload: {
        id: number
    },
    type: string
}

interface GetTasks{
    payload: {
        param: any
    },
    type: string
}

interface CreateTask{
    payload: {
        task: CreateTaskData
    },
    type: string
}

interface UpdateTask{
    payload: {
        params: UpdateTaskData
    },
    type: string
}

function* get_tasks({ payload: { param }, type }: GetTasks): SagaIterator {
    try {
        const response = yield call(getTasksApi, param);
        const tasks = response.data;

        yield put(TasksApiResponseSuccess(TasksActionTypes.GET_TASKS, tasks));
    } catch (error: any) {
        yield put(TasksApiResponseError(TasksActionTypes.GET_TASKS, error));
    }
}

function* get_task({ payload: { id }, type }: GetTask): SagaIterator {
    try {
        const response = yield call(getTaskApi, id);
        const task = response.data;

        yield put(TasksApiResponseSuccess(TasksActionTypes.GET_TASK, task));
    } catch (error: any) {
        yield put(TasksApiResponseError(TasksActionTypes.GET_TASK, error));
    }
}

function* create_task(action: CreateTask): SagaIterator {
    try {
        const response = yield call(createTaskApi, action.payload.task);
        const data = response.data;

        yield put(TasksApiResponseSuccess(TasksActionTypes.CREATE_TASK, data));
    } catch (error: any) {
        yield put(TasksApiResponseError(TasksActionTypes.CREATE_TASK, error));
    }
}
function* update_task(action: UpdateTask): SagaIterator {
    try {
        const response = yield call(updateTaskApi, action.payload.params);
        const data = response.data;

        yield put(TasksApiResponseSuccess(TasksActionTypes.UPDATE_TASK, data));
    } catch (error: any) {
        yield put(TasksApiResponseError(TasksActionTypes.UPDATE_TASK, error));
    }
}

function* delete_task(action: DeleteTask): SagaIterator {
    try {
        // console.log('saga', action.payload.id)
        const response = yield call(deleteTaskApi, action.payload.id);
        const data = response.data;

        yield put(TasksApiResponseSuccess(TasksActionTypes.DELETE_TASK, data));
    } catch (error: any) {
        yield put(TasksApiResponseError(TasksActionTypes.DELETE_TASK, error));
    }
}


export function* watchGetTasks() {
    yield takeEvery(TasksActionTypes.GET_TASKS, get_tasks);
}

export function* watchGetTask() {
    yield takeEvery(TasksActionTypes.GET_TASK, get_task);
}

export function* watchCreateTask() {
    yield takeEvery(TasksActionTypes.CREATE_TASK, create_task);
}

export function* watchUpdateTask() {
    yield takeEvery(TasksActionTypes.UPDATE_TASK, update_task);
}

export function* watchDeleteTask() {
    yield takeEvery(TasksActionTypes.DELETE_TASK, delete_task);
}

function* TasksSaga() {
    yield all([fork(watchGetTasks), fork(watchGetTask), fork(watchCreateTask), fork(watchUpdateTask), fork(watchDeleteTask)]);
}

export default TasksSaga;
