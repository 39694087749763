import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_divisions as getDivisionsApi,
    get_division as getDivisionApi,
    create_division as createDivisionApi,
    delete_division as deleteDivisionApi,
    update_division as updateDivisionApi,
} from '../../helpers/';

// actions
import { DivisionsApiResponseSuccess, DivisionsApiResponseError } from './actions';

// constants
import {DivisionData, DivisionsActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetDivision{
    payload: {
        id: number
    },
    type: string
}

interface GetDivisions{
    payload: {
        param: any
    },
    type: string
}

interface CreateDivision{
    payload: {
        param: DivisionData
    },
    type: string
}

interface UpdateDivision{
    payload: {
        param: DivisionData
    },
    type: string
}

interface DeleteDivision{
    payload: {
        id: number
    },
    type: string
}

function* get_divisions({ payload: { param }, type }: GetDivisions): SagaIterator {
    try {
        const response = yield call(getDivisionsApi, param);
        const Divisions = response.data;

        yield put(DivisionsApiResponseSuccess(DivisionsActionTypes.GET_DIVISIONS, Divisions));
    } catch (error: any) {
        yield put(DivisionsApiResponseError(DivisionsActionTypes.GET_DIVISION, error));
    }
}

function* get_division({ payload: { id }, type }: GetDivision): SagaIterator {
    try {
        const response = yield call(getDivisionApi, id);
        const data = response.data;

        yield put(DivisionsApiResponseSuccess(DivisionsActionTypes.GET_DIVISION, data));
    } catch (error: any) {
        yield put(DivisionsApiResponseError(DivisionsActionTypes.GET_DIVISION, error));
    }
}

function* create_division({ payload: { param }, type }: CreateDivision): SagaIterator {
    try {
        const response = yield call(createDivisionApi, param);
        const data = response.data;

        yield put(DivisionsApiResponseSuccess(DivisionsActionTypes.CREATE_DIVISION, data));
    } catch (error: any) {
        yield put(DivisionsApiResponseError(DivisionsActionTypes.CREATE_DIVISION, error));
    }
}

function* delete_division(action: DeleteDivision): SagaIterator {
    try {
        const response = yield call(deleteDivisionApi, action.payload.id);
        const data = response.data;

        yield put(DivisionsApiResponseSuccess(DivisionsActionTypes.DELETE_DIVISION, data));
    } catch (error: any) {
        yield put(DivisionsApiResponseError(DivisionsActionTypes.DELETE_DIVISION, error));
    }
}

function* update_division(action: UpdateDivision): SagaIterator {
    try {
        const response = yield call(updateDivisionApi, action.payload.param);
        const data = response.data;

        yield put(DivisionsApiResponseSuccess(DivisionsActionTypes.UPDATE_DIVISION, data));
    } catch (error: any) {
        yield put(DivisionsApiResponseError(DivisionsActionTypes.UPDATE_DIVISION, error));
    }
}



export function* watchGetDivisions() {
    yield takeEvery(DivisionsActionTypes.GET_DIVISIONS, get_divisions);
}

export function* watchGetDivision() {
    yield takeEvery(DivisionsActionTypes.GET_DIVISION, get_division);
}

export function* watchCreateDivision() {
    yield takeEvery(DivisionsActionTypes.CREATE_DIVISION, create_division);
}

export function* watchDeleteDivision() {
    yield takeEvery(DivisionsActionTypes.DELETE_DIVISION, delete_division);
}

export function* watchUpdateDivision() {
    yield takeEvery(DivisionsActionTypes.UPDATE_DIVISION, update_division);
}

function* DivisionsSaga() {
    yield all([fork(watchUpdateDivision), fork(watchGetDivisions), fork(watchGetDivision), fork(watchCreateDivision), fork(watchDeleteDivision)]);
}

export default DivisionsSaga;
