import { APICore } from './apiCore';
const api = new APICore();

// account
function get_analytics(param: any) {
    const baseUrl = '/analytics';
    if (param.year) return api.get(`${baseUrl}`, param);
    else return api.get(`${baseUrl}/`, param)
    // return api.get(`${baseUrl}`, param);
}

export {get_analytics};
