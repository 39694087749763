// constants
import {FileData, FilesActionTypes, FilesData} from './constants';

const INIT_STATE = {
    files: [],
    loading: false,
    file: {}
};

interface FilesActionType {
    type:
        | FilesActionTypes.API_RESPONSE_SUCCESS
        | FilesActionTypes.API_RESPONSE_ERROR
        | FilesActionTypes.GET_FILES
        | FilesActionTypes.GET_FILE
        | FilesActionTypes.CREATE_FILE
        | FilesActionTypes.DELETE_FILE
        | FilesActionTypes.UPDATE_FILE
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    files: FileData[] | [];
    loading?: boolean;
    value?: boolean;
    file?: {}
}

const Files = (state: State = INIT_STATE, action: FilesActionType): any => {
    switch (action.type) {
        case FilesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FilesActionTypes.GET_FILES: {
                    return {
                        ...state,
                        files: action.payload.data,
                        loading: false,
                    };
                }

                case FilesActionTypes.GET_FILE: {
                    return {
                        ...state,
                        file: action.payload.data,
                        loading: false,
                    };
                }

                case FilesActionTypes.CREATE_FILE: {
                    // console.log('reducer file', action.payload.data)
                    return {
                        ...state,
                        files: [...state.files, action.payload.data],
                        loading: false,
                    };
                }

                case FilesActionTypes.UPDATE_FILE: {
                    return {
                        ...state,
                        files: state.files.filter(file => file.id !== +action.payload.data.id),
                        loading: false,
                    };
                }

                case FilesActionTypes.DELETE_FILE: {
                    // console.log('reducer', action.payload.data)
                    return {
                        ...state,
                        files: state.files.filter(file => file.id !== +action.payload.data.id),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case FilesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FilesActionTypes.GET_FILES: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case FilesActionTypes.GET_FILE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case FilesActionTypes.CREATE_FILE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                case FilesActionTypes.UPDATE_FILE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case FilesActionTypes.DELETE_FILE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case FilesActionTypes.GET_FILES:
            return { ...state, loading: true };
        case FilesActionTypes.GET_FILE:
            return { ...state, loading: true };
        case FilesActionTypes.CREATE_FILE:
            return { ...state, loading: true };
        case FilesActionTypes.UPDATE_FILE:
            return { ...state, loading: true };
        case FilesActionTypes.DELETE_FILE:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Files;
