import { APICore } from './apiCore';
import {CreateNoteData} from "../../redux/notes/constants";

const api = new APICore();

function create_note(param: CreateNoteData) {
    const baseUrl = '/note/';
    return api.create(`${baseUrl}`, param);
}

function delete_note(param: any) {
    const baseUrl = '/note/';
    return api.delete(`${baseUrl}${param}/`);
}


export {create_note, delete_note};
