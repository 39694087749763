// constants
import {NotesActionTypes} from './constants';

const INIT_STATE = {
    notes: {},
    loading: false,
    note: {}
};

interface NotesActionType {
    type:
        | NotesActionTypes.API_RESPONSE_SUCCESS
        | NotesActionTypes.API_RESPONSE_ERROR
        | NotesActionTypes.DELETE_NOTE
        | NotesActionTypes.CREATE_NOTE
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    notes?: any;
    loading?: boolean;
    note?: {}
}

const Notes = (state: State = INIT_STATE, action: NotesActionType): any => {
    switch (action.type) {
        case NotesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case NotesActionTypes.CREATE_NOTE: {
                    return {
                        ...state,
                        // notes: [...state.notes.filter(item => item.id !== action.payload.data.id)],
                        loading: false,
                    };
                }

                case NotesActionTypes.DELETE_NOTE: {
                    return {
                        ...state,
                        // notes: state.notes.filter(item => item.id !== action.payload.data.id),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case NotesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case NotesActionTypes.DELETE_NOTE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case NotesActionTypes.CREATE_NOTE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case NotesActionTypes.CREATE_NOTE:
            return { ...state, loading: true };
        case NotesActionTypes.DELETE_NOTE:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Notes;
