// constants
import {AnalyticsActionTypes, AnalyticsData} from './constants';

export interface AnalyticsActionType {
    type:
        | AnalyticsActionTypes.API_RESPONSE_SUCCESS
        | AnalyticsActionTypes.API_RESPONSE_ERROR
        | AnalyticsActionTypes.GET_ANALYTICS
    payload: {} | string;
}

// common success
export const AnalyticsApiResponseSuccess = (actionType: string, data: AnalyticsData | {}): AnalyticsActionType => ({
    type: AnalyticsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const AnalyticsApiResponseError = (actionType: string, data: AnalyticsData | {}): AnalyticsActionType => ({
    type: AnalyticsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getAnalytics = (param:any): AnalyticsActionType => ({
    type: AnalyticsActionTypes.GET_ANALYTICS,
    payload: {param},
});