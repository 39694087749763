export enum FilesActionTypes {
    API_RESPONSE_SUCCESS = '@@files/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@files/API_RESPONSE_ERROR',

    GET_FILES = '@@files/GET_FILES',
    GET_FILE = '@@files/GET_FILE',
    CREATE_FILE = '@@files/CREATE_FILE',
    DELETE_FILE = '@@files/DELETE_FILE',
    UPDATE_FILE = '@@files/UPDATE_FILE',
}

export interface FileData extends File {
    id?: number;
    preview?: string;
    formattedSize?: string;
}

export interface ReceivedFileData{
    id: number;
    "name": string,
    "document": string,
    "doc_type": string,
    "user": number,
    is_archived: boolean
}

export interface CreateFileData {
    "name": string,
    "document": string,
    "doc_type": string
}

export interface ArchiveFileData {
    id: number;
    is_archived: boolean
}

export interface FilesData{
    files: FileData[]
}
