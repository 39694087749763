// constants
import {AnalyticsData, AnalyticsActionTypes} from './constants';

const INIT_STATE = {
    analytics: {},
    loading: false,
};

interface AnalyticsActionType {
    type:
        | AnalyticsActionTypes.API_RESPONSE_SUCCESS
        | AnalyticsActionTypes.API_RESPONSE_ERROR
        | AnalyticsActionTypes.GET_ANALYTICS
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    loading?: boolean;
    value?: boolean;
    analytics?: {}
}

const Analytics = (state: State = INIT_STATE, action: AnalyticsActionType): any => {
    switch (action.type) {
        case AnalyticsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AnalyticsActionTypes.GET_ANALYTICS: {
                    return {
                        ...state,
                        analytics: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case AnalyticsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AnalyticsActionTypes.GET_ANALYTICS: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AnalyticsActionTypes.GET_ANALYTICS:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Analytics;
