// constants
import {FilesActionTypes, FilesData, FileData, CreateFileData, ArchiveFileData} from './constants';

export interface FilesActionType {
    type:
        | FilesActionTypes.API_RESPONSE_SUCCESS
        | FilesActionTypes.API_RESPONSE_ERROR
        | FilesActionTypes.GET_FILES
        | FilesActionTypes.GET_FILE
        | FilesActionTypes.CREATE_FILE
        | FilesActionTypes.DELETE_FILE
        | FilesActionTypes.UPDATE_FILE
    payload: {} | string;
}

// common success
export const filesApiResponseSuccess = (actionType: string, data: FilesData | {}): FilesActionType => ({
    type: FilesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const filesApiResponseError = (actionType: string, data: FilesData | {}): FilesActionType => ({
    type: FilesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getFiles = (param:any): FilesActionType => ({
    type: FilesActionTypes.GET_FILES,
    payload: {param},
});

export const getFile = (id: number): FilesActionType => ({
    type: FilesActionTypes.GET_FILE,
    payload: {id},
});

export const deleteFile = (id: number): FilesActionType => ({
    type: FilesActionTypes.DELETE_FILE,
    payload: {id},
});

export const archiveFile = (param: ArchiveFileData): FilesActionType => {
    return {
        type: FilesActionTypes.UPDATE_FILE,
        payload: {param}
    }
};

export const createStringFile = (file: CreateFileData): FilesActionType => ({
    type: FilesActionTypes.CREATE_FILE,
    payload: {file},
});
