import { APICore } from './apiCore';
import {ArchiveMedcenterData, MedcenterData} from "../../redux/medcenters/constants";

const api = new APICore();

// account
function get_medcenters(param: any) {
    const baseUrl = '/hospital/';
    return api.get(`${baseUrl}`, param);
}

function get_medcenter(id:number) {
    const baseUrl = '/hospital/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_medcenter(params: any) {
    const baseUrl = '/hospital/';
    return api.create(`${baseUrl}`, params);
}

function update_medcenter(params: MedcenterData) {
    const baseUrl = '/hospital/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function archive_medcenter(params: ArchiveMedcenterData) {
    const baseUrl = '/hospital/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function delete_medcenter(params: any) {
    const baseUrl = '/hospital/';
    return api.delete(`${baseUrl}${params}/`);
}

export { get_medcenters, get_medcenter, create_medcenter, delete_medcenter, update_medcenter, archive_medcenter};
