// constants
import {DivisionsActionTypes, DivisionsData, DivisionData} from './constants';

export interface DivisionsActionType {
    type:
        | DivisionsActionTypes.API_RESPONSE_SUCCESS
        | DivisionsActionTypes.API_RESPONSE_ERROR
        | DivisionsActionTypes.GET_DIVISIONS
        | DivisionsActionTypes.GET_DIVISION
        | DivisionsActionTypes.CREATE_DIVISION
        | DivisionsActionTypes.DELETE_DIVISION
        | DivisionsActionTypes.UPDATE_DIVISION
    payload: {} | string;
}

// common success
export const DivisionsApiResponseSuccess = (actionType: string, data: DivisionsData | {}): DivisionsActionType => ({
    type: DivisionsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const DivisionsApiResponseError = (actionType: string, data: DivisionsData | {}): DivisionsActionType => ({
    type: DivisionsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getDivisions = (param:any): DivisionsActionType => ({
    type: DivisionsActionTypes.GET_DIVISIONS,
    payload: {param},
});

export const getDivision = (id: number): DivisionsActionType => ({
    type: DivisionsActionTypes.GET_DIVISION,
    payload: {id},
});

export const deleteDivision = (id: number): DivisionsActionType => ({
    type: DivisionsActionTypes.DELETE_DIVISION,
    payload: {id},
});

export const updateDivision = (param: DivisionData): DivisionsActionType => ({
    type: DivisionsActionTypes.UPDATE_DIVISION,
    payload: {param},
});

export const createDivision = (param: DivisionData): DivisionsActionType => ({
    type: DivisionsActionTypes.CREATE_DIVISION,
    payload: {param},
});
