// constants
import {EmployeeData, EmployeesActionTypes} from './constants';

const INIT_STATE = {
    employees: [],
    loading: false,
    employee: {},
    profile: {}
};

interface EmployeesActionType {
    type:
        | EmployeesActionTypes.API_RESPONSE_SUCCESS
        | EmployeesActionTypes.API_RESPONSE_ERROR
        | EmployeesActionTypes.GET_EMPLOYEES
        | EmployeesActionTypes.GET_EMPLOYEE
        | EmployeesActionTypes.CREATE_EMPLOYEE
        | EmployeesActionTypes.DELETE_EMPLOYEE
        | EmployeesActionTypes.UPDATE_EMPLOYEE
        | EmployeesActionTypes.UPDATE_PROFILE
        | EmployeesActionTypes.GET_PROFILE
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    employees: EmployeeData[] | [];
    loading?: boolean;
    value?: boolean;
    employee?: {};
    profile?: {};
}

const Employees = (state: State = INIT_STATE, action: EmployeesActionType): any => {
    switch (action.type) {
        case EmployeesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EmployeesActionTypes.GET_EMPLOYEES: {
                    return {
                        ...state,
                        employees: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.GET_PROFILE: {
                    return {
                        ...state,
                        profile: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.UPDATE_PROFILE: {
                    return {
                        ...state,
                        profile: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.GET_EMPLOYEE: {
                    return {
                        ...state,
                        employee: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.CREATE_EMPLOYEE: {
                    return {
                        ...state,
                        employees: [...state.employees, action.payload.data],
                        loading: false,
                    };
                }

                case EmployeesActionTypes.UPDATE_EMPLOYEE: {
                    return {
                        ...state,
                        employees: [...state.employees.filter(employee => employee.id !== action.payload.data.id), action.payload.data],
                        loading: false,
                    };
                }

                case EmployeesActionTypes.DELETE_EMPLOYEE: {
                    return {
                        ...state,
                        employees: state.employees.filter(employee => employee.id !== action.payload.data.id),
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case EmployeesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case EmployeesActionTypes.GET_EMPLOYEES: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.GET_PROFILE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.UPDATE_PROFILE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.GET_EMPLOYEE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.CREATE_EMPLOYEE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                case EmployeesActionTypes.UPDATE_EMPLOYEE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case EmployeesActionTypes.DELETE_EMPLOYEE: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case EmployeesActionTypes.GET_EMPLOYEES:
            return { ...state, loading: true };
        case EmployeesActionTypes.GET_EMPLOYEE:
            return { ...state, loading: true };
        case EmployeesActionTypes.GET_PROFILE:
            return { ...state, loading: true };
        case EmployeesActionTypes.UPDATE_PROFILE:
            return { ...state, loading: true };
        case EmployeesActionTypes.DELETE_EMPLOYEE:
            return { ...state, loading: true };
        case EmployeesActionTypes.CREATE_EMPLOYEE:
            return { ...state, loading: true };
        case EmployeesActionTypes.UPDATE_EMPLOYEE:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Employees;
