import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    get_pharmacies as getPharmaciesApi,
    get_pharmacy as getPharmacyApi,
    create_pharmacy as createPharmacyApi,
    delete_pharmacy as deletePharmacyApi,
    update_pharmacy as updatePharmacyApi,
    archive_pharmacy as archivePharmacyApi,
} from '../../helpers/';

// actions
import { pharmaciesApiResponseSuccess, pharmaciesApiResponseError } from './actions';

// constants
import {PharmacyData, PharmaciesActionTypes, UpdatePharmacyData, ArchivePharmacyData} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface GetPharmacy{
    payload: {
        id: number
    },
    type: string
}

interface GetPharmacies{
    payload: {
        param: any
    },
    type: string
}

interface CreatePharmacy{
    payload: {
        pharmacy: PharmacyData
    },
    type: string
}

interface UpdatePharmacy{
    payload: {
        params: UpdatePharmacyData
    },
    type: string
}

interface ArchivePharmacy{
    payload: {
        params: ArchivePharmacyData
    },
    type: string
}


interface DeletePharmacy{
    payload: {
        id: number
    },
    type: string
}

function* get_pharmacies({ payload: { param }, type }: GetPharmacies): SagaIterator {
    try {
        const response = yield call(getPharmaciesApi, param);
        const pharmacies = response.data;

        yield put(pharmaciesApiResponseSuccess(PharmaciesActionTypes.GET_PHARMACIES, pharmacies));
    } catch (error: any) {
        yield put(pharmaciesApiResponseError(PharmaciesActionTypes.GET_PHARMACIES, error));
    }
}

function* get_pharmacy({ payload: { id }, type }: GetPharmacy): SagaIterator {
    try {
        const response = yield call(getPharmacyApi, id);
        const doctor = response.data;

        yield put(pharmaciesApiResponseSuccess(PharmaciesActionTypes.GET_PHARMACY, doctor));
    } catch (error: any) {
        yield put(pharmaciesApiResponseError(PharmaciesActionTypes.GET_PHARMACY, error));
    }
}

function* create_pharmacy({ payload: { pharmacy }, type }: CreatePharmacy): SagaIterator {
    try {
        const response = yield call(createPharmacyApi, pharmacy);
        const data = response.data;

        yield put(pharmaciesApiResponseSuccess(PharmaciesActionTypes.CREATE_PHARMACY, data));
    } catch (error: any) {
        yield put(pharmaciesApiResponseError(PharmaciesActionTypes.CREATE_PHARMACY, error));
    }
}

function* update_pharmacy({ payload: { params }, type }: UpdatePharmacy): SagaIterator {
    try {
        const response = yield call(updatePharmacyApi, params);
        const data = response.data;

        yield put(pharmaciesApiResponseSuccess(PharmaciesActionTypes.UPDATE_PHARMACY, data));
    } catch (error: any) {
        yield put(pharmaciesApiResponseError(PharmaciesActionTypes.UPDATE_PHARMACY, error));
    }
}

function* archive_pharmacy({ payload: { params }, type }: ArchivePharmacy): SagaIterator {
    try {
        const response = yield call(archivePharmacyApi, params);
        const data = response.data;

        yield put(pharmaciesApiResponseSuccess(PharmaciesActionTypes.ARCHIVE_PHARMACY, data));
    } catch (error: any) {
        yield put(pharmaciesApiResponseError(PharmaciesActionTypes.ARCHIVE_PHARMACY, error));
    }
}

function* delete_pharmacy(action: DeletePharmacy): SagaIterator {
    try {
        const response = yield call(deletePharmacyApi, action.payload.id);
        const data = response.data;

        yield put(pharmaciesApiResponseSuccess(PharmaciesActionTypes.DELETE_PHARMACY, data));
    } catch (error: any) {
        yield put(pharmaciesApiResponseError(PharmaciesActionTypes.DELETE_PHARMACY, error));
    }
}


export function* watchGetPharmacies() {
    yield takeEvery(PharmaciesActionTypes.GET_PHARMACIES, get_pharmacies);
}

export function* watchGetPharmacy() {
    yield takeEvery(PharmaciesActionTypes.GET_PHARMACY, get_pharmacy);
}

export function* watchCreatePharmacy() {
    yield takeEvery(PharmaciesActionTypes.CREATE_PHARMACY, create_pharmacy);
}

export function* watchUpdatePharmacy() {
    yield takeEvery(PharmaciesActionTypes.UPDATE_PHARMACY, update_pharmacy);
}

export function* watchArchivePharmacy() {
    yield takeEvery(PharmaciesActionTypes.ARCHIVE_PHARMACY, archive_pharmacy);
}

export function* watchDeletePharmacy() {
    yield takeEvery(PharmaciesActionTypes.DELETE_PHARMACY, delete_pharmacy);
}

function* pharmaciesSaga() {
    yield all([fork(watchUpdatePharmacy), fork(watchArchivePharmacy), fork(watchGetPharmacies), fork(watchGetPharmacy), fork(watchCreatePharmacy), fork(watchDeletePharmacy)]);
}

export default pharmaciesSaga;
