import React from 'react';

import Routes from './routes/Routes';


// Themes
// For Default import Theme.scss
import './assets/scss/Theme.scss';

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";


const App = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyANALyEJ5ilhpQgB3rwbXv1CFqgRM2D_bQ",
        authDomain: "marbika-3e41d.firebaseapp.com",
        projectId: "marbika-3e41d",
        storageBucket: "marbika-3e41d.appspot.com",
        messagingSenderId: "1001483302723",
        appId: "1:1001483302723:web:29cf8f05404570570aa1f6"
    };

// Initialize Firebase
initializeApp(firebaseConfig);

    return (
        <>
            <Routes/>
        </>
    );
};

export default App;
