import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';


// helpers
import {
    create_note as createNoteApi,
    delete_note as deleteNoteApi,
} from '../../helpers/';

// actions
import { NotesApiResponseSuccess, NotesApiResponseError } from './actions';

// constants
import {CreateNoteData, NotesActionTypes} from './constants';

/**
 * Login the user
 * @param {*} payload - username and password
 */

interface CreateNote{
    payload: {
        param: CreateNoteData
    },
    type: string
}

interface DeleteNote{
    payload: {
        id: number
    },
    type: string
}

function* create_Note({ payload: { param }, type }: CreateNote): SagaIterator {
    try {
        const response = yield call(createNoteApi, param);
        const data = response.data;

        yield put(NotesApiResponseSuccess(NotesActionTypes.CREATE_NOTE, data));
    } catch (error: any) {
        yield put(NotesApiResponseError(NotesActionTypes.CREATE_NOTE, error));
    }
}

function* delete_Note(action: DeleteNote): SagaIterator {
    try {
        const response = yield call(deleteNoteApi, action.payload.id);
        const data = response.data;

        yield put(NotesApiResponseSuccess(NotesActionTypes.DELETE_NOTE, data));
    } catch (error: any) {
        yield put(NotesApiResponseError(NotesActionTypes.DELETE_NOTE, error));
    }
}


export function* watchCreateNote() {
    yield takeEvery(NotesActionTypes.CREATE_NOTE, create_Note);
}

export function* watchDeleteNote() {
    yield takeEvery(NotesActionTypes.DELETE_NOTE, delete_Note);
}

function* NotesSaga() {
    yield all([fork(watchCreateNote), fork(watchDeleteNote)]);
}

export default NotesSaga;
