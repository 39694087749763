import { APICore } from './apiCore';
import {DivisionData} from "../../redux/divisions/constants";

const api = new APICore();

// account
function get_divisions(param: any) {
    const baseUrl = '/division/';
    return api.get(`${baseUrl}`, param);
}

function get_division(id:number) {
    const baseUrl = '/division/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_division(params: DivisionData) {
    const baseUrl = '/division/';
    return api.create(`${baseUrl}`, params);
}

function update_division(params: DivisionData) {
    const baseUrl = '/division/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function delete_division(param: any) {
    const baseUrl = '/division/';
    return api.delete(`${baseUrl}${param}`);
}

export { get_divisions, get_division, create_division, delete_division, update_division};
