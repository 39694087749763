import { APICore } from './apiCore';
import {ArchivePharmacyData, PharmacyData, UpdatePharmacyData} from "../../redux/pharmacies/constants";

const api = new APICore();

// account
function get_pharmacies(param: any) {
    const baseUrl = '/pharmacy/';
    return api.get(`${baseUrl}`, param);
}

function get_pharmacy(id:number) {
    const baseUrl = '/pharmacy/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_pharmacy(params: PharmacyData) {
    const baseUrl = '/pharmacy/';
    return api.create(`${baseUrl}`, params);
}

function update_pharmacy(params: UpdatePharmacyData) {
    const baseUrl = '/pharmacy/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function archive_pharmacy(params: ArchivePharmacyData) {
    const baseUrl = '/pharmacy/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function delete_pharmacy(param: any) {
    const baseUrl = '/pharmacy/';
    return api.delete(`${baseUrl}${param}`);
}


export { get_pharmacies, get_pharmacy, create_pharmacy, delete_pharmacy, update_pharmacy, archive_pharmacy};
