// constants
import {PharmacyData, PharmaciesActionTypes, PharmaciesData} from './constants';
import {EmployeesActionTypes} from "../employees/constants";

const INIT_STATE = {
    pharmacies: {
        pharmacy: [],
        general_info: {}
    },
    loading: false,
    pharmacy: {}
};

interface PharmaciesActionType {
    type:
        | PharmaciesActionTypes.API_RESPONSE_SUCCESS
        | PharmaciesActionTypes.API_RESPONSE_ERROR
        | PharmaciesActionTypes.GET_PHARMACIES
        | PharmaciesActionTypes.GET_PHARMACY
        | PharmaciesActionTypes.CREATE_PHARMACY
        | PharmaciesActionTypes.DELETE_PHARMACY
        | PharmaciesActionTypes.UPDATE_PHARMACY
        | PharmaciesActionTypes.ARCHIVE_PHARMACY
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    pharmacies: {
        pharmacy: PharmacyData[] | [],
        general_info: any
    };
    loading?: boolean;
    value?: boolean;
    pharmacy?: {}
}

const Pharmacies = (state: State = INIT_STATE, action: PharmaciesActionType): any => {
    switch (action.type) {
        case PharmaciesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PharmaciesActionTypes.GET_PHARMACIES: {
                    return {
                        ...state,
                        pharmacies: action.payload.data,
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.GET_PHARMACY: {
                    return {
                        ...state,
                        pharmacy: action.payload.data,
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.CREATE_PHARMACY: {
                    return {
                        ...state,
                        pharmacies: {
                            ...state.pharmacies,
                            pharmacies: [...state.pharmacies.pharmacy, action.payload.data]
                        },
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.UPDATE_PHARMACY: {
                    return {
                        ...state,
                        // pharmacies: state.pharmacies.filter(doctor => doctor.id === action.payload.data?.id),
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.ARCHIVE_PHARMACY: {
                    return {
                        ...state,
                        // pharmacies: state.pharmacies.filter(doctor => doctor.id === action.payload.data?.id),
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.DELETE_PHARMACY: {
                    return {
                        ...state,
                        pharmacies: {
                            ...state.pharmacies,
                            pharmacies: state.pharmacies.pharmacy.filter(item => item.id !== action.payload.data.id)
                        },
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case PharmaciesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PharmaciesActionTypes.GET_PHARMACIES: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.GET_PHARMACY: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.CREATE_PHARMACY: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }
                case PharmaciesActionTypes.UPDATE_PHARMACY: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.ARCHIVE_PHARMACY: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                case PharmaciesActionTypes.DELETE_PHARMACY: {
                    return {
                        ...state,
                        error: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case PharmaciesActionTypes.GET_PHARMACIES:
            return { ...state, loading: true };
        case PharmaciesActionTypes.GET_PHARMACY:
            return { ...state, loading: true };
        case PharmaciesActionTypes.CREATE_PHARMACY:
            return { ...state, loading: true };
        case PharmaciesActionTypes.UPDATE_PHARMACY:
            return { ...state, loading: true };
        case PharmaciesActionTypes.DELETE_PHARMACY:
            return { ...state, loading: true };
        case PharmaciesActionTypes.ARCHIVE_PHARMACY:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Pharmacies;
