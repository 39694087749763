import { APICore } from './apiCore';
import {CreateFilialData, FilialData} from "../../redux/filials/constants";

const api = new APICore();

// account
function get_filials(param: any) {
    const baseUrl = '/filial/';
    return api.get(`${baseUrl}`, param);
}

function get_filial(id:number) {
    const baseUrl = '/filial/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_filial(params: CreateFilialData) {
    const baseUrl = '/filial/';
    return api.create(`${baseUrl}`, params);
}

function update_filial(params: CreateFilialData) {
    const baseUrl = '/filial/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function delete_filial(param: any) {
    const baseUrl = '/filial/';
    return api.delete(`${baseUrl}${param}/`);
}

export { get_filials, get_filial, create_filial, delete_filial, update_filial};
