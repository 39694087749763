export enum NotesActionTypes {
    API_RESPONSE_SUCCESS = '@@Notes/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@Notes/API_RESPONSE_ERROR',

    CREATE_NOTE = '@@Notes/CREATE_NOTE',
    DELETE_NOTE = '@@Notes/DELETE_NOTE',
}

export interface CreateNoteData {
    content: string;
    to: string;
    to_id?: number;
}

export interface NotesData{
    notes: CreateNoteData[]
}
