// constants
import {EmployeesActionTypes, EmployeeData, EmployeesData, UpdateEmployeeData, CreateEmployeeData} from './constants';

export interface EmployeesActionType {
    type:
        | EmployeesActionTypes.API_RESPONSE_SUCCESS
        | EmployeesActionTypes.API_RESPONSE_ERROR
        | EmployeesActionTypes.GET_EMPLOYEES
        | EmployeesActionTypes.GET_EMPLOYEE
        | EmployeesActionTypes.CREATE_EMPLOYEE
        | EmployeesActionTypes.DELETE_EMPLOYEE
        | EmployeesActionTypes.UPDATE_EMPLOYEE
        | EmployeesActionTypes.GET_PROFILE
        | EmployeesActionTypes.UPDATE_PROFILE
    payload: {} | string;
}

// common success
export const EmployeesApiResponseSuccess = (actionType: string, data: EmployeesData | {}): EmployeesActionType => ({
    type: EmployeesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const EmployeesApiResponseError = (actionType: string, data: EmployeesData | {}): EmployeesActionType => ({
    type: EmployeesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const getEmployees = (param:any): EmployeesActionType => ({
    type: EmployeesActionTypes.GET_EMPLOYEES,
    payload: {param},
});

export const getProfile = (param:any): EmployeesActionType => ({
    type: EmployeesActionTypes.GET_PROFILE,
    payload: {},
});


export const updateProfile = (employee: EmployeeData): EmployeesActionType => ({
    type: EmployeesActionTypes.UPDATE_PROFILE,
    payload: {employee},
});

export const getEmployee = (id: number): EmployeesActionType => ({
    type: EmployeesActionTypes.GET_EMPLOYEE,
    payload: {id},
});

export const deleteEmployee = (id: number): EmployeesActionType => ({
    type: EmployeesActionTypes.DELETE_EMPLOYEE,
    payload: {id},
});

export const updateEmployeeData = (params: UpdateEmployeeData): EmployeesActionType => ({
    type: EmployeesActionTypes.UPDATE_EMPLOYEE,
    payload: {params},
});

export const createEmployee = (employee: CreateEmployeeData): EmployeesActionType => ({
    type: EmployeesActionTypes.CREATE_EMPLOYEE,
    payload: {employee},
});
