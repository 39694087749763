export enum AnalyticsActionTypes {
    API_RESPONSE_SUCCESS = '@@Analytics/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@Analytics/API_RESPONSE_ERROR',

    GET_ANALYTICS = '@@Analytics/GET_ANALYTICS'
}

export interface VisitUserAndPresentationMonthDetailed {
    days: any[];
    visit_count: any[];
}

export interface VisitUserAndPresentationYear {
    month_detailed: VisitUserAndPresentationMonthDetailed[];
    months: any[];
    visit_per_month: any[];
    visit_per_month_doctor: any[];
    visit_per_month_pharmacy: any[];
    name?: string;
    year_title?: string;
}
export interface VisitUserAndPresentation {
    year_details: VisitUserAndPresentationYear[];
    year_name: string;
}

export interface ElementInfoMonth {
    days: any[];
    count: any[];
}

export interface ElementInfoYear {
    month_detailed: ElementInfoMonth[];
    months: any[];
    count_per_month: any[];
    year_name: string;
}


export interface AnalyticsData {
    visit_info: ElementInfoYear;
    doctor_info: ElementInfoYear;
    hospital_info: ElementInfoYear;
    pharmacy_info: ElementInfoYear;
    presentation_info: ElementInfoYear;
    presentation_visits_counts: VisitUserAndPresentation;
    user_visits_counts: VisitUserAndPresentation;
    years: any[];
}
