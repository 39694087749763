import { APICore } from './apiCore';
import {ArchiveFileData, CreateFileData} from "../../redux/files/constants";

const api = new APICore();

// account
function get_files(param: any) {
    const baseUrl = '/presentation/';
    return api.get(`${baseUrl}`, param);
}

function get_file(id:number) {
    const baseUrl = '/presentation/';
    return api.get(`${baseUrl}${id}`, null);
}

function create_string_file(params: CreateFileData) {
    const baseUrl = '/presentation/';
    return api.create(`${baseUrl}`, params);
}

function archive_file(params: ArchiveFileData) {

    const baseUrl = '/presentation/';
    return api.updatePatch(`${baseUrl}${params.id}/`, params);
}

function delete_file(id: number) {
    // console.log('Files params', id)
    const baseUrl = '/presentation/';
    return api.delete(`${baseUrl}${id}/`);
}


export { get_files, get_file, delete_file, create_string_file, archive_file};
