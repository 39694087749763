// constants
import {CreateNoteData, NotesActionTypes, NotesData} from './constants';

export interface NotesActionType {
    type:
        | NotesActionTypes.API_RESPONSE_SUCCESS
        | NotesActionTypes.API_RESPONSE_ERROR
        | NotesActionTypes.CREATE_NOTE
        | NotesActionTypes.DELETE_NOTE
    payload: {} | string;
}

// common success
export const NotesApiResponseSuccess = (actionType: string, data: NotesData  | {}): NotesActionType => ({
    type: NotesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const NotesApiResponseError = (actionType: string, data: NotesData | {}): NotesActionType => ({
    type: NotesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, data },
});

export const deleteNote = (id: number): NotesActionType => ({
    type: NotesActionTypes.DELETE_NOTE,
    payload: {id},
});

export const createNote = (param: CreateNoteData): NotesActionType => ({
    type: NotesActionTypes.CREATE_NOTE,
    payload: {param},
});
